import React, { useState } from "react";
import { useLocation, Link, Outlet, useParams } from "react-router-dom";
import { FaPlane, FaUmbrellaBeach, FaCar, FaTasks } from "react-icons/fa"; // Import relevant icons
import RowRoutesIcon from "components/RowRoutesIcon/RowRoutesIcon";
import ServiceOptions from "./Other/ServiceOption";
import ServiceRequiredDocuments from "./Other/ServiceRequiredDocuments";
import { setVisaData } from "../../../redux/VisaSlice";
import { useDispatch } from "react-redux";

export default function CreateEnquiry() {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const path = params["*"];
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };
  // This will capture the current route path

  // Define routes for the tabs with icons
  const routes = [
    {
      label: "Flight",
      url: "/admin/create-enquiry/flight/round-trip",
      icon: <FaPlane />,
      bg: [
        "/admin/create-enquiry/flight/round-trip",
        "/admin/create-enquiry/flight/oneway",
        "/admin/create-enquiry/flight/multi-city",
      ],
    },
    {
      label: "Hotel",
      url: "/admin/create-enquiry/hotel",
      icon: <FaUmbrellaBeach />,
      bg: ["/admin/create-enquiry/hotel"],
    },
    {
      label: "Other Service",
      url: "/admin/create-enquiry/other-service",
      icon: <FaTasks />,
      bg: ["/admin/create-enquiry/other-service"],
    },
  ];

  return (
    <section className="mt-6 bg-white p-2 dark:bg-transparent sm:p-4">
      <div className="z-40 flex items-start justify-between gap-4 sm:items-center ">
        <RowRoutesIcon routes={routes} />
        {path === "create-enquiry/other-service" ? (
          <div>
            <button
              onClick={toggleMenu}
              className="cursor-pointer rounded bg-[#bb8e25] px-4 py-1.5 text-white shadow"
            >
              {/* {showMenu ? "Hide Options" : "Show Options"} */}
              Services
            </button>

            {/* Menu */}
            {showMenu && (
              <div className="relative">
                <div className="menu absolute right-0 top-2 z-50 w-64 rounded bg-base-200 p-0 shadow-lg">
                  {ServiceOptions.map((service, index) => (
                    <div
                      key={index}
                      className="menu-item group relative hover:bg-primary hover:text-white"
                    >
                      {/* Main Item */}
                      <span className="block cursor-pointer px-4 py-3 font-semibold">
                        {service.name}
                      </span>
                      {/* Submenu on Hover */}
                      <div className="absolute top-11 z-50 hidden w-full flex-col border border-gray-200 bg-white shadow-lg group-hover:flex sm:right-full sm:top-0 sm:w-56">
                        {service?.options?.map((option) => (
                          <a
                            key={option.id}
                            href="#"
                            className="px-4 py-2 text-gray-700 hover:bg-gray-100"
                            onClick={() => {
                              const data = ServiceRequiredDocuments.find(
                                (item) => item.id === option.id // assuming 'service.id' refers to the current service item
                              );
                              dispatch(setVisaData(data));
                              setShowMenu(false);
                            }}
                          >
                            {option.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="my-5 rounded-md bg-transparent ">
        <Outlet />
      </div>
    </section>
  );
}
