const ServiceOptions = [
  {
    name: "Visa »",
    options: [
      {
        id: 1,
        name: "UK or USA Visa",
      },
      {
        id: 2,
        name: "India Visa",
      },
    ],
  },
  {
    name: "Passport »",
    options: [
      {
        id: 3,
        name: "Indian Passport Renewal",
      },
    ],
  },
  {
    name: "PR Card Renewal »",
    options: [
      {
        id: 4,
        name: "PR CARD",
      },
    ],
  },
  {
    name: "Surrender »",
    options: [
      {
        id: 5,
        name: "SURRENDER OF INDIAN PASSPORT",
      },
    ],
  },
  {
    name: "Citizenship »",
    options: [
      {
        id: 6,
        name: "CITIZENSHIP",
      },
    ],
  },
  {
    name: "Landed Paper »",
    options: [
      {
        id: 7,
        name: "LOST LANDED PAPER",
      },
    ],
  },
  {
    name: "OCI »",
    options: [
      {
        id: 8,
        name: "OCI",
      },
    ],
  },
  {
    name: "Citizenship Certificate »",
    options: [
      {
        id: 9,
        name: "CITIZENSHIP/OATH CERTIFICATE",
      },
    ],
  },
];

export default ServiceOptions;
