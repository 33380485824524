import PieChart from "components/charts/PieChart";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import { IoNavigateCircle } from "react-icons/io5";

const PieChartCard = ({
  heading,
  data,
  name,
  lastName,
  colors,
  thisSearchFor,
  navigateTo,
  filterWithEmployee,
}) => {
  const navigate = useNavigate();

  // Map data to include colors
  const addColorIntoData = data?.map((item, index) => ({
    ...item,
    color: colors[index % colors.length], // Assign colors cyclically
  }));

  const labels = addColorIntoData?.map(
    (item) => item?.[name]?.label || item?.[name]?.[name]?.[lastName]
  );

  const pieChartOptions = {
    labels: labels,
    colors,
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: {
      mode: null,
    },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
        cursor: "pointer",
      },
    },
    fill: {
      colors,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "12px",
        backgroundColor: "#000000",
      },
    },
  };

  return (
    <section>
      {addColorIntoData.length > 0 && (
        <Card extra="rounded-[20px] p-3 dark:bg-white ">
          <div className="flex flex-row justify-center px-3 pt-2">
            <div>
              <h4 className="text-lg font-bold text-navy-700">{heading}</h4>
            </div>
          </div>

          <div className="mb-auto flex h-[250px] w-full items-center justify-center">
            <PieChart
              options={pieChartOptions}
              series={addColorIntoData?.map((e) => e?.count)}
            />
          </div>
          <div className="grid grid-cols-3  place-items-center items-center justify-center rounded-2xl px-6 py-3 text-center shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            {addColorIntoData?.reverse()?.map((item) => {
              return (
                <div
                  className="flex flex-col items-center justify-center"
                  key={item?.id} // Use item.id if it's available for uniqueness
                >
                  <div className="flex cursor-pointer items-start justify-center">
                    <IoNavigateCircle
                      style={{ color: item?.color }}
                      className="relative top-0.5"
                    />
                    <p
                      className="ml-1 text-sm font-normal text-gray-600"
                      onClick={() => {
                        navigate(navigateTo, {
                          state: {
                            id:
                              item?.[name]?.[name]?.id ||
                              item?.[name]?.[name]?.[lastName],
                            name:
                              item?.[name]?.label ||
                              item?.[name]?.[name]?.[lastName],
                            disabledFilter: false,
                            thisSearchFor: thisSearchFor,
                            filterWithEmployee: filterWithEmployee,
                          },
                        });
                      }}
                    >
                      {item?.[name]?.label || item?.[name]?.[name]?.[lastName]}{" "}
                      <span
                        style={{
                          background: item?.color,
                          minWidth: "20px", // Adjust width to make it more consistent
                          height: "20px", // Adjust height to match width for a perfect circle
                        }}
                        className="flex items-center justify-center rounded-full px-2 text-center text-xs text-black"
                      >
                        {item?.count}
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      )}
    </section>
  );
};

export default PieChartCard;
