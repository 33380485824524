import InputField from "components/fields/InputField";
import Logo from "components/Home/Logo/Logo";
import React from "react";
import { useForm } from "react-hook-form";
import {
  FaGlobe,
  FaMailBulk,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { MdOutgoingMail } from "react-icons/md";
import { logo2 } from "utils/images";
import { logo3 } from "utils/images";
import { logo1 } from "utils/images";
import { enquiryFormImg } from "utils/images";

const contactInfo = [
  {
    icon: <FaMailBulk size={25} className="mr-2 text-[#bb8e25]" />,
    text: (
      <a
        href="mailto:katariatravel@gmail.com?subject=Enquiry&body=Hello, I would like to inquire about your services."
        className="hover:text-blue-500 hover:underline"
      >
        katariatravel@gmail.com
      </a>
    ),
  },
  {
    icon: <FaPhoneAlt size={25} className="mr-2 text-[#bb8e25]" />,
    text: (
      <a
        href="tel:+19056781200"
        className="hover:text-blue-500 hover:underline"
      >
        +1- 905-678-1200
      </a>
    ),
  },
  {
    icon: <FaGlobe size={25} className="mr-2 text-[#bb8e25]" />,
    text: (
      <a
        href="https://katariatravel.com/"
        className="hover:text-blue-500 hover:underline"
      >
        www.katariatravel.com
      </a>
    ),
  },
];

const Enquiry = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    reset(); // Reset form after submission
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-br from-blue-100 to-[#bb8e25] p-4">
      <div className="grid w-full max-w-6xl grid-cols-1 items-end gap-4 rounded-lg bg-white p-8 shadow-lg lg:grid-cols-2">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-1 items-end gap-2 md:grid-cols-2"
          >
            {/* Full Name */}
            <Logo logo1={logo1} logo2={logo2} logo3={logo3} />
            <h2 className="text-center text-2xl font-bold text-gray-900 sm:text-left">
              Flight Booking Enquiry
            </h2>
            <InputField
              label="Full Name"
              id="fullName"
              name="fullName"
              placeholder="Enter your full name"
              type="text"
              register={register}
              rules={{ required: "Full Name is required" }}
              errorMessage={errors.fullName?.message}
            />

            {/* Email */}
            <InputField
              label="Email Address"
              id="email"
              name="email"
              placeholder="Enter your email address"
              type="email"
              register={register}
              rules={{
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Enter a valid email address",
                },
              }}
              errorMessage={errors.email?.message}
            />

            {/* Phone Number */}
            <InputField
              label="Phone Number"
              id="phone"
              name="phone"
              placeholder="Enter your phone number"
              type="tel"
              register={register}
              rules={{
                required: "Phone number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Enter a valid 10-digit phone number",
                },
              }}
              errorMessage={errors.phone?.message}
            />

            {/* Departure City */}
            <InputField
              label="Departure City"
              id="departureCity"
              name="departureCity"
              placeholder="Enter your departure city"
              type="text"
              register={register}
              rules={{ required: "Departure City is required" }}
              errorMessage={errors.departureCity?.message}
            />

            {/* Destination City */}
            <InputField
              label="Destination City"
              id="destinationCity"
              name="destinationCity"
              placeholder="Enter your destination city"
              type="text"
              register={register}
              rules={{ required: "Destination City is required" }}
              errorMessage={errors.destinationCity?.message}
            />

            {/* Travel Date */}
            <InputField
              label="Travel Date"
              id="travelDate"
              name="travelDate"
              placeholder="Select your travel date"
              type="date"
              register={register}
              rules={{ required: "Travel Date is required" }}
              errorMessage={errors.travelDate?.message}
            />

            {/* Return Date (Optional) */}
            <InputField
              label="Return Date"
              id="returnDate"
              name="returnDate"
              placeholder="Select your return date"
              type="date"
              register={register}
              rules={{}}
              errorMessage={errors.returnDate?.message}
            />

            {/* Number of Passengers */}
            <InputField
              label="Number of Passengers"
              id="passengers"
              name="passengers"
              placeholder="Enter number of passengers"
              type="number"
              register={register}
              rules={{ required: "Number of Passengers is required" }}
              errorMessage={errors.passengers?.message}
            />

            {/* Submit Button */}
            <div className="col-span-1 md:col-span-2">
              <button
                type="submit"
                className="w-full rounded-lg bg-[#bb8e25] px-4 py-2 font-semibold text-white transition duration-200 hover:bg-[#8f7029]"
              >
                Submit Enquiry
              </button>
            </div>
          </form>
        </div>

        {/* Image Section for Larger Screens */}
        <div className="flex flex-col justify-between gap-2">
          <div className="flex cursor-pointer flex-col items-center justify-center gap-2 text-gray-700 sm:hidden">
            <FaPhoneAlt size={25} className="mr-2 text-[#bb8e25]" />
            <p className="cursor-pointer text-center text-xs hover:text-blue-500 hover:underline">
              <a href="tel:+19056781200">+1- 905-678-1200</a>
            </p>
          </div>
          <div className="hidden  cursor-pointer flex-col items-center justify-center gap-2 text-gray-700 sm:flex">
            <FaMapMarkerAlt size={25} className="mr-2 text-[#bb8e25]" />
            <p className=" cursor-pointer text-center text-xs hover:text-blue-500 hover:underline">
              <a
                href="https://maps.app.goo.gl/GcR8NQwcP5cQvexn6"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 hover:underline"
              >
                UNIT 12, 4550 EBENEZER RD BRAMPTON ONTARIO L6P 1H4, Canada
              </a>
            </p>
          </div>

          <div className="mb-4 grid grid-cols-2 gap-4 text-center lg:grid-cols-3 lg:text-left">
            {contactInfo.map((info, index) => (
              <div
                key={index}
                className={`mt-2 flex cursor-pointer flex-col items-center justify-center gap-2 text-gray-700 ${
                  index === 1 ? "hidden sm:flex" : ""
                }`}
              >
                {info.icon}
                <p className="cursor-pointer text-center text-xs hover:text-blue-500 hover:underline">
                  {info.text}
                </p>
              </div>
            ))}
          </div>
          <div className="flex cursor-pointer  flex-col items-center justify-center gap-2 text-gray-700 sm:hidden">
            <FaMapMarkerAlt size={25} className="mr-2 text-[#bb8e25]" />
            <p className=" cursor-pointer text-center text-xs hover:text-blue-500 hover:underline">
              <a
                href="https://maps.app.goo.gl/GcR8NQwcP5cQvexn6"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 hover:underline"
              >
                UNIT 12, 4550 EBENEZER RD BRAMPTON ONTARIO L6P 1H4, Canada
              </a>
            </p>
          </div>
          <div>
            <img
              src={enquiryFormImg}
              alt="Travel Enquiry"
              className="hidden h-auto w-full rounded-lg shadow-md lg:flex"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
