import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import {
  FaCalendarAlt,
  FaPhone,
  FaPlaneArrival,
  FaPlaneDeparture,
} from "react-icons/fa";
import InputField from "components/fields/InputField";
import FormDropdown from "components/FormDropdown/FormDropdown";
import userResources from "api/userResources";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { selectUserInfo } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import DisableScrollForNumberInputs from "components/DisableScrollForNumberInputs/DisableScrollForNumberInputs";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
        label: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bangalore)",
        label: "Kempegowda International Airport (Bangalore)",
      },
      {
        value: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
        label: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
      },
    ],
  },
  {
    label: "Canada",
    options: [
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
      {
        value: "Vancouver International Airport",
        label: "Vancouver International Airport",
      },
      {
        value: "Montreal-Pierre Elliott Trudeau International Airport",
        label: "Montreal-Pierre Elliott Trudeau International Airport",
      },
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
    ],
  },
  {
    label: "United States",
    options: [
      {
        value: "Los Angeles International Airport (LAX)",
        label: "Los Angeles International Airport (LAX)",
      },
      {
        value: "John F. Kennedy International Airport (New York)",
        label: "John F. Kennedy International Airport (New York)",
      },
      {
        value: "O'Hare International Airport (Chicago)",
        label: "O'Hare International Airport (Chicago)",
      },
      {
        value: "Dallas/Fort Worth International Airport (Dallas)",
        label: "Dallas/Fort Worth International Airport (Dallas)",
      },
    ],
  },
  {
    label: "United Kingdom",
    options: [
      {
        value: "Heathrow Airport (London)",
        label: "Heathrow Airport (London)",
      },
      { value: "Gatwick Airport (London)", label: "Gatwick Airport (London)" },
      { value: "Manchester Airport", label: "Manchester Airport" },
      { value: "Edinburgh Airport", label: "Edinburgh Airport" },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        value: "Sydney Kingsford Smith Airport",
        label: "Sydney Kingsford Smith Airport",
      },
      { value: "Melbourne Airport", label: "Melbourne Airport" },
      { value: "Brisbane Airport", label: "Brisbane Airport" },
      { value: "Perth Airport", label: "Perth Airport" },
    ],
  },
];

// Array for Status options

export default function CreateEnquiryForm({
  thisoneWay,
  packageType,
  updateData,
  type,
}) {
  const Navigate = useNavigate();
  const userInfo = useSelector(selectUserInfo);
  const [editing, setEditing] = useState(updateData ? true : false);
  const [disabled, setDisabled] = useState(false);
  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [airpotsTo, setAirpotsTo] = useState(null);
  const [departure_date, setDepartureDate] = useState(
    updateData?.departure_date || new Date().toISOString().split("T")[0]
  );
  const [return_date, setReturnDate] = useState(
    thisoneWay
      ? ""
      : updateData?.return_date || new Date().toISOString().split("T")[0]
  );
  const [phoneNumber, setPhoneNumber] = useState(
    updateData?.phone_number || ""
  ); // State for phone number
  const [employeeData, setEmployeeData] = useState([]);
  const [airlineData, setAirlineData] = useState([]);
  const [transactionTypeData, setTransactionTypeData] = useState([]);
  const [enquirySourceData, setEnquirySourceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [followedUpAt, setFollowedUpAt] = useState(
    updateData?.followed_up_at ? updateData?.followed_up_at : ""
  );
  const [status, setStatus] = useState("");
  const From = (value) => {
    setAirpotsFrom(value);

    // Update formData with the selected airport
    setFormData((prevFormData) => ({
      ...prevFormData,
      from: value?.value || "",
    }));

    // Clear the error for 'from' when a valid value is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      from: value ? "" : prevErrors.from, // Update to an empty string if there's a value
    }));
  };

  const To = (value) => {
    setAirpotsTo(value);

    // Update formData with the selected airport
    setFormData((prevFormData) => ({
      ...prevFormData,
      to: value?.value || "",
    }));

    // Clear the error for 'to' when a valid value is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      to: value ? "" : prevErrors.to, // Clear the error if there's a valid selection
    }));
  };

  console.log(updateData, "updateData");

  const [formData, setFormData] = useState({
    title: updateData ? updateData.title : "",
    customer_name: updateData ? updateData.customer_name : "",
    email: updateData ? updateData.email : "",
    phone_number: updateData ? updateData.phone_number : phoneNumber,
    from: "",
    to: "",
    departure_date: updateData
      ? updateData.departure_date
      : new Date().toISOString().split("T")[0],
    return_date: thisoneWay ? "" : new Date().toISOString().split("T")[0],
    adult: updateData ? updateData.adult : 1,
    child: updateData ? updateData.child : 0,
    infant: updateData ? updateData.infant : 0,
    class_of_travel: updateData ? updateData.class_of_travel : "economy",
    enquiry_source_id: updateData ? updateData?.enquiry_source_id : "",
    type: updateData ? updateData.type : type || "Round Trip",
    package_type: updateData ? updateData.package_type : packageType,
    air_line_id: updateData ? updateData.air_line_id : "",
    booking_reference: updateData ? updateData.booking_reference : "",
    budget: updateData ? updateData.budget : "",
    invoice_number: updateData ? updateData.invoice_number : "",
    enquiry_status_id: updateData ? updateData?.enquiry_status_id : "",
    transaction_type_id: updateData ? updateData?.transaction_type_id : "",
    assigned_to_user_id: updateData
      ? updateData?.assignedToUser?.id
      : userInfo?.data?.id,
    remark: updateData ? updateData.remark : "",
    paid_amount: updateData ? updateData.paid_amount : "",
  });

  const [errors, setErrors] = useState({
    title: "",
    customer_name: "",
    email: "",
    phone_number: "",
    from: "",
    to: "",
    departure_date: "",
    return_date: "",
    adult: "",
    child: "",
    infant: "",
    class_of_travel: "",
    enquiry_source_id: "",
    // type: "",
    air_line_id: "",
    booking_reference: "",
    budget: "",
    invoice_number: "",
    enquiry_status_id: "",
    transaction_type_id: "",
    assigned_to_user_id: "",
    remark: "",
    paid_amount: "",
  });

  const validateForm = () => {
    const newErrors = {};

    // Required field validations
    if (!formData.title?.trim()) {
      newErrors.title = "Title is required";
    }
    if (!formData.customer_name?.trim()) {
      newErrors.customer_name = "Customer name is required";
    }
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is not valid";
    }
    if (!formData.phone_number?.trim()) {
      newErrors.phone_number = "Phone number is required";
    }

    // Add validations for other fields
    if (!formData.from?.trim()) {
      newErrors.from = "Departure airport is required";
    }
    if (!formData.to?.trim()) {
      newErrors.to = "Destination airport is required";
    }
    if (!formData.departure_date?.trim()) {
      newErrors.departure_date = "Departure date is required";
    }
    if (!formData.return_date?.trim() && !thisoneWay) {
      newErrors.return_date = "Return date is required";
    }
    if (formData.adult < 1) {
      newErrors.adult = "At least one adult is required";
    }
    if (formData.child < 0) {
      newErrors.child = "Number of child cannot be negative";
    }
    if (formData.infant < 0) {
      newErrors.infant = "Number of infant cannot be negative";
    }
    if (!formData.class_of_travel?.trim()) {
      newErrors.class_of_travel = "Flight type is required";
    }
    if (!formData.enquiry_source_id) {
      newErrors.enquiry_source_id = "Enquiry source is required";
    }
    // if (!formData.type?.trim()) {
    //   newErrors.type = "Enquiry type is required";
    // }
    if (!formData.air_line_id) {
      newErrors.air_line_id = "Airline ID is required";
    }
    // if (!formData.booking_reference?.trim()) {
    //   newErrors.booking_reference = "Booking reference is required";
    // }
    if (!formData.budget) {
      newErrors.budget = "Budget is required";
    }
    // if (!formData.invoice_number?.trim()) {
    //   newErrors.invoice_number = "Invoice number is required";
    // }
    if (!formData.enquiry_status_id) {
      newErrors.enquiry_status_id = "Enquiry status is required";
    }
    if (
      (formData?.paid_amount > 0 || updateData?.paid_amount > 0) &&
      !formData.transaction_type_id
    ) {
      newErrors.transaction_type_id = "Transaction type is required";
    }
    if (!formData.assigned_to_user_id) {
      newErrors.assigned_to_user_id = "Assigned user ID is required";
    }
    // if (!formData.remark?.trim()) {
    //   newErrors.remark = "Remark is required";
    // }
    // New validation for preferred airline
    if (!formData.air_line_id) {
      newErrors.air_line_id = "Preferred airline is required";
    }
    if (!formData.paid_amount) {
      newErrors.paid_amount = "Paid amount is required";
    } else if (
      updateData?.paid_amount &&
      formData.paid_amount < updateData.paid_amount
    ) {
      newErrors.paid_amount = `Paid amount cannot be less than ${updateData.paid_amount}`;
    }

    // Set the errors state
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleDepartDate = (date) => {
    setDepartureDate(date); // Update local state
    setFormData((prev) => ({
      ...prev, // Spread the previous state
      departure_date: date, // Update the departure_date
    }));
  };

  const handleReturnDate = (date) => {
    setReturnDate(date);
    setFormData((prev) => ({
      ...prev, // Spread the previous state
      return_date: date, // Update the departure_date
    }));
  };

  const totalTravellers = formData.adult + formData.child + formData.infant;

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  // Handle input change
  const handleDateChange = (e) => {
    const value = e.target.value; // Input value in the format YYYY-MM-DDTHH:MM
    const formattedValue = value.replace("T", " ") + ":00"; // Replace 'T' with a space and add seconds
    setFollowedUpAt(formattedValue); // Update state
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submissionData = {
      ...formData,
      status: updateData ? status : "pending",
      followed_up_at: followedUpAt ? followedUpAt : null,
    };

    if (thisoneWay) {
      delete submissionData.return_date;
    }
    if (updateData) {
      submissionData.id = updateData.parent_id || updateData.id;
    }

    // Remove paid_amount if it is the same as updateData.paid_amount
    // if (formData.paid_amount === updateData?.paid_amount) {
    //   delete submissionData.paid_amount;
    // }

    if (validateForm()) {
      setDisabled(true); // Disable the button during submission
      toast.loading("Submitting...", { toastId: "loadingToast" }); // Show loading toast

      try {
        const response = await submitEnquiry(submissionData);

        if (response.status) {
          setFormData({
            title: "",
            customer_name: "",
            email: "",
            phone_number: "",
            from: "",
            to: "",
            departure_date: new Date().toISOString().split("T")[0],
            return_date: thisoneWay
              ? ""
              : new Date().toISOString().split("T")[0],
            adult: 1,
            child: 0,
            infant: 0,
            class_of_travel: "economy",
            enquiry_source_id: "",
            type: type || "Round Trip",
            package_type: packageType,
            air_line_id: "",
            booking_reference: "",
            budget: "",
            invoice_number: "",
            enquiry_status_id: "",
            transaction_type_id: "",
            assigned_to_user_id: "",
            remark: "",
            paid_amount: "",
          });
          // Reset additional states
          setPhoneNumber(null);
          setAirpotsFrom(null);
          setAirpotsTo(null);
          setDepartureDate(new Date().toISOString().split("T")[0]);
          setReturnDate(
            thisoneWay ? "" : new Date().toISOString().split("T")[0]
          );

          toast.update("loadingToast", {
            render: updateData
              ? "Record updated successfully!"
              : "Submission completed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        }
        setTimeout(() => {
          Navigate(
            updateData?.NavigateTo === "assignenquirylist"
              ? "/admin/assigned-enquiry-list"
              : updateData?.NavigateTo === "createdenquiryList"
              ? "/admin/created-enquiry-list"
              : updateData?.navigateToAllList === true
              ? "/admin/enquiry-list"
              : "/admin/enquiry-list"
          );
        }, 1000);
        console.log(response);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";

        toast.update("loadingToast", {
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      } finally {
        setDisabled(false); // Re-enable the button after submission
      }
    }
  };
  // Helper function for API call logic
  const submitEnquiry = async (submissionData) => {
    if (updateData?.NavigateTo === "assignenquirylist") {
      return userResources.createEnquiryRoundAndOneWayByAgent(submissionData);
    } else {
      return userResources.createEnquiry(submissionData);
    }
  };
  // Function to fetch all necessary data
  const fetchData = async () => {
    try {
      // Define an array of API calls
      const apiCalls = [
        {
          method: userResources.adminemployeeslist,
          // params: {},
          callback: setEmployeeData,
          errorMsg: "User list API call failed:",
        },
        {
          method: userResources.airlines,
          params: { page: 0, size: 99999 },
          callback: setAirlineData,
          errorMsg: "Airlines API call failed:",
        },
        {
          method: userResources.getSources,
          // params: {},
          callback: setEnquirySourceData,
          errorMsg: "Error fetching Enquiry Source data:",
        },
        {
          method: userResources?.enquityStatus,
          // params: {},
          callback: setStatusData,
          errorMsg: "Error fetching Enquiry Status data:",
        },
        {
          method: userResources?.transactionType,
          // params: {},
          callback: setTransactionTypeData,
          errorMsg: "Error fetching Enquiry Status data:",
        },
      ];

      // Call all APIs simultaneously using Promise.allSettled
      const results = await Promise.allSettled(
        apiCalls.map((api) => api.method(api.params))
      );

      // Process each result
      results.forEach((result, index) => {
        if (result.status === "fulfilled" && result.value?.status) {
          const data = result.value.data.data.map((item) => ({
            value: item.id,
            label: item.name || item.airline_name, // Use item.airline_name if it exists
          }));
          apiCalls[index].callback(data);
        } else {
          console.log(apiCalls[index].errorMsg, result.reason);
        }
      });
    } catch (error) {
      console.log("An unexpected error occurred:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="max-w-full py-2">
      <ScrollToTopOnRouteChange />
      <DisableScrollForNumberInputs />

      <form onSubmit={handleSubmit} className="">
        <div className="grid grid-cols-1 gap-1">
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Enter Title"
              name="title"
              value={formData.title}
              onChange={(e) => handleChange("title", e.target.value)}
              state={errors.title ? "error" : ""}
              errorMessage={errors.title} // Pass the error message here
              placeholder="Enter Title"
            />
            <InputField
              label="Customer Name" // Ensure to keep the label
              name="customer_name" // Use name to match formData structure
              value={formData.customer_name} // Controlled input for customer name
              onChange={(e) => handleChange("customer_name", e.target.value)} // Handle changes
              state={errors.customer_name ? "error" : ""} // Set state based on errors
              errorMessage={errors.customer_name} // Pass the error message here
              placeholder="Customer Name" // Placeholder for the input field
            />
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Email Address"
              name="email" // Use name for form data consistency
              type="email" // Set type to "email" for validation
              placeholder="Customer Email"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes
              value={formData.email} // Controlled input
              onChange={(e) => handleChange("email", e.target.value)} // Handle changes
              state={errors.email ? "error" : ""} // Set state based on errors
              errorMessage={errors.email} // Pass the error message here
            />
            <div>
              <label
                htmlFor="phone"
                className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
              >
                Phone Number
              </label>
              <div className="relative">
                <div className="flex">
                  <PhoneInputWithCountrySelect
                    defaultCountry="CA"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone_number: value,
                      }));
                      if (value) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phone_number: "",
                        })); // Clear phone_no error if value is provided
                      }
                    }}
                    placeholder="Enter Phone Number"
                    // required
                    className="h-10 w-full rounded-md border px-2 text-lg focus:outline-none focus:ring-2 dark:!border-white/10"
                  />
                </div>
                <div className="absolute right-4 top-4">
                  <FaPhone />
                </div>
              </div>
              {errors.phone_number && (
                <span className="text-sm text-red-600">
                  {errors.phone_number}
                </span>
              )}
            </div>
          </div>

          <div className="my-2 rounded-md border border-gray-700 bg-gray-900 py-5 text-white">
            <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3">
              <div className="border-b border-r border-gray-700 border-b-gray-100 bg-transparent px-3 py-4 sm:border-b-0">
                <div className="flex items-center gap-2">
                  <FaPlaneDeparture />
                  <label className="text-md text-yellow-500">FROM</label>
                </div>
                <Select
                  value={airpotsFrom}
                  onChange={From} // Ensure this is your correct handler
                  options={options}
                  isSearchable={true}
                  placeholder="Select..."
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full ${
                        errors.from
                          ? "border-red-500 border text-red-500"
                          : "text-gray-900"
                      }  cursor-pointer mt-2 relative text-lg overflow-hidden transition-all outline-none duration-300 focus:outline-none rounded-sm cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 border-red-500 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length} 👉 Airports
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
                {/* Error Message */}
                {errors.from && (
                  <span className="text-xs text-red-500">{errors.from}</span>
                )}
              </div>

              <div className="border-b border-r border-gray-700 border-b-gray-100 px-3 py-4 sm:border-b-0">
                <div className="flex items-center gap-2">
                  <FaPlaneArrival />
                  <label className="text-md text-yellow-500">To</label>
                </div>
                <Select
                  value={airpotsTo}
                  onChange={To}
                  options={options}
                  isSearchable={true}
                  placeholder="Select..."
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full ${
                        errors.to
                          ? "border-red-500 border text-red-500"
                          : "text-gray-900"
                      }  cursor-pointer mt-2 relative text-lg overflow-hidden transition-all outline-none duration-300 focus:outline-none rounded-sm cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 border-red-500 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length}
                        👉 Airpots
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {/* // data represents each option in the list */}
                      {data.label}
                    </li>
                  )}
                />
                {/* Error Message */}
                {errors.to && (
                  <span className="text-xs text-red-500">{errors.to}</span>
                )}
              </div>
              <div className="border-b border-r border-gray-700 border-b-gray-100 px-3 py-4 sm:border-b-0">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md text-yellow-500">DEPART</label>
                </div>
                <input
                  type="date"
                  className={`z-50 mt-2 w-full cursor-pointer rounded-sm bg-transparent bg-white p-2 text-gray-900 outline-0 `}
                  value={departure_date} // Use the updated state
                  onChange={(e) => {
                    handleDepartDate(e.target.value);
                    // Update RETURN min date if pickupDate changes
                    if (e.target.value > return_date && !thisoneWay) {
                      setReturnDate(e.target.value); // Reset drop date to pickup date if it's earlier
                    }
                  }} // Update date
                  min={new Date().toISOString().split("T")[0]}
                />
                {/* Error Message */}
                {errors.departure_date && (
                  <span className="text-xs text-red-500">
                    {errors.departure_date}
                  </span>
                )}
              </div>

              <div
                className={`${
                  thisoneWay ? " text-gray-700" : ""
                } border-b border-r border-gray-700 border-b-gray-100 px-3 py-4 sm:border-b-0`}
              >
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label
                    className={`text-md ${thisoneWay ? "" : "text-yellow-500"}`}
                  >
                    RETURN
                  </label>
                </div>
                <input
                  type="date"
                  className={`z-50 mt-2 w-full cursor-pointer rounded-sm bg-transparent  p-2 outline-0 ${
                    thisoneWay ? " " : "bg-white text-gray-900"
                  }`}
                  value={return_date} // Use the updated state
                  onChange={(e) => {
                    handleReturnDate(e.target.value);
                  }} // Update date
                  disabled={thisoneWay} // Disable if one-way
                  min={departure_date || new Date().toISOString().split("T")[0]}
                />
                {/* Error Message */}
                {errors.return_date && (
                  <span className="text-xs text-red-500">
                    {errors.return_date}
                  </span>
                )}
              </div>

              <div className=" px-3 py-4 ">
                <div className="flex items-center gap-2 text-white">
                  <FaCalendarAlt />
                  <label className="text-md text-yellow-500">TRAVELLERS</label>
                </div>
                <div className="dropdown dropdown-top w-full">
                  <div tabIndex={0} role="button" className="cursor-pointer">
                    <h3 className="text-lg">{totalTravellers} Travellers</h3>
                    <p className="text-sm">{formData.class_of_travel}</p>
                  </div>
                  <div
                    tabIndex={0}
                    className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow"
                  >
                    {/* Adults Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <h4 className="">Adults</h4>
                        <h4>{formData.adult}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.adult > 1) {
                                setFormData((prev) => ({
                                  ...prev,
                                  adult: prev.adult - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                adult: prev.adult + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Children Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div className="">
                          <h4>Children</h4>
                          <h6 className="">2-12 Years</h6>
                        </div>
                        <h4>{formData.child}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.child > 0) {
                                setFormData((prev) => ({
                                  ...prev,
                                  child: prev.child - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                child: prev.child + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Infants Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div>
                          <h4>Infants</h4>
                          <h6 className="">0-23 Months</h6>
                        </div>
                        <h4>{formData.infant}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.infant > 0) {
                                setFormData((prev) => ({
                                  ...prev,
                                  infant: prev.infant - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                infant: prev.infant + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Flight Type Selection */}
                    <div className="text-gray-900">
                      <div className="flex cursor-pointer items-center  gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="economy"
                          name="class_of_travel"
                          value="economy"
                          checked={formData.class_of_travel === "economy"}
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label htmlFor="economy" className="cursor-pointer">
                          Economy
                        </label>
                      </div>
                      <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="premiumEconomy"
                          name="class_of_travel"
                          value="premium_economy"
                          checked={
                            formData.class_of_travel === "premium_economy"
                          }
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label
                          htmlFor="premiumEconomy"
                          className="cursor-pointer"
                        >
                          Premium Economy
                        </label>
                      </div>
                      <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="business"
                          name="class_of_travel"
                          value="business"
                          checked={formData.class_of_travel === "business"}
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label htmlFor="business" className="cursor-pointer">
                          Business
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 items-center gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Enquiry Source
              </label>
              <Select
                value={enquirySourceData.find(
                  (source) => source.value === formData.enquiry_source_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  handleChange(
                    "enquiry_source_id",
                    selectedOption?.value || ""
                  ); // Update handler
                }}
                options={enquirySourceData} // Format options
                isSearchable={true} // Enable search functionality
                placeholder="Select..."
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.enquiry_source_id,
                  errorsBorder: errors.enquiry_source_id ? true : false,
                })} // Pass parameters here}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.enquiry_source_id && (
                <span className="text-xs text-red-500">
                  {errors.enquiry_source_id}
                </span>
              )}
            </div>
            {/* Status */}
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Status
              </label>
              <Select
                value={statusData.find(
                  (status) => status.value === formData.enquiry_status_id
                )}
                onChange={(selectedOption) => {
                  handleChange(
                    "enquiry_status_id",
                    selectedOption?.value || ""
                  );
                }}
                options={statusData}
                isSearchable={true}
                placeholder="Select Status"
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.enquiry_status_id,
                  errorsBorder: errors.enquiry_status_id ? true : false,
                })} // Pass parameters here
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? "bg-gray-200 text-gray-900"
                        : "bg-blue-800 text-gray-200"
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.enquiry_status_id && (
                <span className="text-xs text-red-500">
                  {errors.enquiry_status_id}
                </span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Preferred Airline
              </label>
              <Select
                value={airlineData.find(
                  (airline) => airline.value === formData.air_line_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  handleChange("air_line_id", selectedOption?.value || ""); // Update handler
                }}
                options={airlineData} // Format options
                isSearchable={true} // Enable search functionality
                placeholder="Select..."
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.air_line_id,
                  errorsBorder: errors.air_line_id ? true : false,
                })} // Pass parameters here}}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.air_line_id && (
                <span className="text-xs text-red-500">
                  {errors.air_line_id}
                </span>
              )}
            </div>

            {/* Assigned To */}
            <div>
              <label className="my-2 block text-sm  font-medium text-gray-900 dark:text-[#bb8e25]">
                Assigned To
              </label>
              <Select
                value={employeeData.find(
                  (employee) => employee.value === formData.assigned_to_user_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  setStatus("pending");
                  handleChange(
                    "assigned_to_user_id",
                    selectedOption?.value || ""
                  );
                }} // Handle change
                options={employeeData}
                isDisabled={updateData?.status === "accept" ? true : false}
                isSearchable={true}
                placeholder="Select..."
                classNames={getSelectClassNames({
                  isDisabled: updateData?.status === "accept" ? true : false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.assigned_to_user_id,
                  errorsBorder: errors.assigned_to_user_id ? true : false,
                })} // Pass parameters here}}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-4 py-2 transition duration-200 ${
                      data.isSelected
                        ? "bg-blue-800 text-gray-100 shadow-lg"
                        : "bg-gray-200 text-gray-900 hover:bg-gray-300"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      <span className="font-semibold">{data.label}</span>
                      <span className="text-sm text-gray-600">
                        ID: {data?.value}
                      </span>
                    </div>
                  </li>
                )}
              />
              {errors.assigned_to_user_id && (
                <span className="text-xs text-red-500">
                  {errors.assigned_to_user_id}
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Price Quote"
              name="budget" // Use name for form data consistency
              type="number" // Keep type as "text" or adjust to "number" if preferred
              placeholder="Price Quote"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.budget} // Controlled input
              onChange={(e) => handleChange("budget", e.target.value)} // Handle changes
              state={errors.budget ? "error" : ""} // Set state based on errors
              errorMessage={errors.budget} // Pass the error message here
            />

            <InputField
              label="Paid Amount"
              id="paid_amount" // Add an id for accessibility
              name="paid_amount" // Ensure name matches form data structure
              type="number" // Use "number" for numeric input
              placeholder="Paid Amount"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.paid_amount} // Controlled input
              onChange={(e) => {
                const value = Number(e.target.value); // Ensure it's a number
                handleChange("paid_amount", e.target.value); // Update paid_amount
                if (value === 0 || value > updateData?.paid_amount) {
                  handleChange("transaction_type_id", ""); // Clear transaction_type_id when paid_amount is 0
                } else if (value === updateData?.paid_amount) {
                  handleChange(
                    "transaction_type_id",
                    updateData?.transaction_type_id
                  );
                } else if (value < updateData?.paid_amount) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    paid_amount: `Paid amount cannot be less than ${updateData.paid_amount}`, // Set error
                  }));
                }
              }}
              state={errors.paid_amount ? "error" : ""} // Set state based on errors
              errorMessage={errors.paid_amount} // Pass the error message here
            />
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Transaction Type
              </label>
              <Select
                value={transactionTypeData.find(
                  (status) => status.value === formData.transaction_type_id
                )}
                isDisabled={
                  formData.paid_amount == 0 ||
                  formData.paid_amount == updateData?.paid_amount ||
                  formData.paid_amount < updateData?.paid_amount
                    ? true
                    : false
                }
                onChange={(selectedOption) => {
                  handleChange(
                    "transaction_type_id",
                    selectedOption?.value || ""
                  );
                }}
                options={transactionTypeData}
                isSearchable={true}
                placeholder="Select Transaction Type"
                classNames={getSelectClassNames({
                  isDisabled:
                    formData.paid_amount == 0 ||
                    formData.paid_amount == updateData?.paid_amount ||
                    formData.paid_amount < updateData?.paid_amount
                      ? true
                      : false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.transaction_type_id,
                  errorsBorder: errors.transaction_type_id ? true : false,
                })}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.transaction_type_id && (
                <span className="text-xs text-red-500">
                  {errors.transaction_type_id}
                </span>
              )}
            </div>

            <InputField
              label="Booking Reference No."
              name="booking_reference" // Use name for form data consistency
              type="number" // Keep type as "text" for general text input
              placeholder="Booking Reference No."
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.booking_reference} // Controlled input
              onChange={(e) =>
                handleChange("booking_reference", e.target.value)
              } // Handle changes
              state={errors.booking_reference ? "error" : ""} // Set state based on errors
              errorMessage={errors.booking_reference} // Pass the error message here
            />
            <InputField
              label="Invoice Number"
              name="invoice_number" // Use name for form data consistency
              type="text" // Keep type as "text" or adjust to "number" if preferred
              placeholder="Invoice Number"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.invoice_number} // Controlled input
              onChange={(e) => handleChange("invoice_number", e.target.value)} // Handle changes
              state={errors.invoice_number ? "error" : ""} // Set state based on errors
              errorMessage={errors.invoice_number} // Pass the error message here
            />
            <InputField
              label="Remark"
              id="remark" // Add an id for accessibility
              name="remark" // Ensure name matches form data structure
              type="text" // Keep type as "text"; this is optional for textareas
              placeholder="Remark"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.remark} // Controlled input; corrected to use 'remarks'
              onChange={(e) => handleChange("remark", e.target.value)} // Handle changes
              state={errors.remark ? "error" : ""} // Set state based on errors
              errorMessage={errors.remark} // Pass the error message here
              isTextArea={true} // Indicate that this is a textarea
            />
          </div>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2  sm:gap-4 sm:gap-y-0">
            <div>
              <label
                htmlFor="followedUpAt"
                className="block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
              >
                Followed Up At:
              </label>
              <input
                type="datetime-local" // Ensures the format matches the requirement
                id="followedUpAt"
                name="followedUpAt"
                value={
                  followedUpAt
                    ? followedUpAt.replace(" ", "T").slice(0, 16)
                    : ""
                } // Convert back for input display
                onChange={handleDateChange}
                className="mt-1 block w-full cursor-pointer rounded-md border bg-gray-100 p-2 shadow-sm outline-none dark:!border-white/10 dark:bg-transparent sm:text-sm"
              />
            </div>
            <div className="relative top-2 flex w-full items-end justify-end">
              <SubmitButton
                editing={editing}
                disabled={disabled}
                add="Submit"
                edit="Update"
                extraClass="btn w-full border-0"
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}
