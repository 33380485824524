import React, { useState, useEffect, useRef } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import userResources from "api/userResources"; // Replace with your API methods
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { IoSend } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { chatBotImg, avtar } from "utils/images";
import { selectUserInfo } from "../../../../redux/userSlice";
import msgSoundSound from "../../../../assets/Sound/send.mp3";
import notificationSound from "../../../../assets/Sound/notification.wav";
import { FaSearch } from "react-icons/fa";
import { addMessage } from "../../../../redux/messageSlice";

export default function ChatApp() {
  const dispatch = useDispatch();
  const reduxMessages = useSelector((state) => state?.messages);
  const userInfo = useSelector(selectUserInfo);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [user, setUser] = useState();
  const [forceUpdate, setForceUpdate] = useState(0);
  const [disable, setDisable] = useState(false);
  const [userSearch, setUserSearch] = useState();

  // Laravel Echo setup
  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
  }, [selectedUser, reduxMessages]);

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({});
  };

  const usersList = async () => {
    const params = {
      name: userSearch, // Assuming `userSearch` holds the search value
    };

    try {
      const res = await userResources.adminemployeeslist(0, 9999, params); // Passing the parameters here
      if (res.status) {
        const filteredUsers = res.data.data.filter(
          (e) => e.id !== userInfo.data.id
        );
        setUsers(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching user list:", error);
    }
  };

  // Fetch chat messages from the server
  const fetchMessages = async (userId) => {
    const data = {
      receiver: userId,
    };
    try {
      const res = await userResources.getChatMessages(data);
      if (res.status) {
        setMessages(res.data);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // Handle user selection
  const handleUserSelection = () => {
    setSelectedUser(user);
    fetchMessages(user?.id);
  };

  useEffect(() => {
    handleUserSelection();
    fetchMessages(user?.id);
  }, [user]);

  // Send message
  const sendMessage = async () => {
    setDisable(true);
    if (selectedUser && newMessage.trim()) {
      const messageData = {
        message: newMessage, // The message content
        receiver_id: selectedUser.id, // The receiver's user ID
      };

      try {
        const res = await userResources.sendMsg(messageData);
        if (res.status) {
          // Play sound when the message is successfully sent
          const audio = new Audio(msgSoundSound);
          audio.play();

          handleUserSelection();
          fetchMessages(user?.id);
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...messageData, sender: "You" },
          ]);
          setNewMessage(""); // Clear the input field
          setDisable(false);
        }
      } catch (error) {
        setDisable(false);
        console.error("Error sending message:", error);
      }
      setDisable(false);
    }
  };

  function parseMessage(message) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Helper function to split long words
    const splitLongWords = (text) => {
      const maxChar = 20;
      if (text.length <= maxChar) {
        return text;
      }

      // Split into chunks of `maxChar` characters and join with a newline
      const chunks = text.match(new RegExp(`.{1,${maxChar}}`, "g"));
      return chunks.join("\n");
    };

    return message.split(urlRegex).map((part, index) =>
      urlRegex.test(part) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          className="break-all text-white underline"
        >
          {splitLongWords(part)}
        </a>
      ) : (
        // Process non-URL text to split long words
        splitLongWords(part)
      )
    );
  }

  useEffect(() => {
    usersList();
  }, []);

  useEffect(() => {
    usersList();
  }, [reduxMessages]);

  useEffect(() => {
    // Set a timeout to delay the function execution by 2 seconds
    const timer = setTimeout(() => {
      usersList(); // Call the usersList function after 2 seconds
    }, 300);

    // Cleanup function to clear the timeout if the userSearch changes before 2 seconds
    return () => clearTimeout(timer);
  }, [userSearch]); // The effect will run when userSearch changes

  useEffect(() => {
    fetchMessages(user?.id); // Uncomment if necessary
    setForceUpdate(0);
  }, [forceUpdate, reduxMessages]);

  useEffect(() => {
    fetchMessages(user?.id); // Uncomment if necessary
  }, [forceUpdate, reduxMessages]);

  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    // chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="mt-3 flex">
      <div className="relative -left-3 w-1/3  bg-gray-900 bg-gradient-to-b p-4 text-white sm:left-0">
        <div className="relative w-full">
          <input
            type="text"
            value={userSearch}
            placeholder="Search..."
            className="input mb-2 w-full bg-white pr-10 text-gray-900" // Add padding-right for the icon
            onChange={(e) => setUserSearch(e.target.value)}
          />
          <FaSearch className="absolute right-3 top-6 -translate-y-1/2 transform text-gray-600" />
        </div>
        <ul className="hide-scroll-bar h-[74vh] overflow-y-scroll">
          <ul className="hide-scroll-bar h-[74vh] overflow-y-scroll">
            {users.map((user) => {
              // Filter messages for the current user
              const userMessages = reduxMessages.filter(
                (message) => message.sender_id === user.id
              );

              // Get the last message or a default message
              const lastMessage =
                userMessages.length > 0
                  ? userMessages[userMessages.length - 1].message
                  : "No messages yet";

              return (
                <div
                  key={user.id}
                  className={`flex cursor-pointer items-center gap-2 rounded p-2 hover:bg-[#bb8e25] hover:text-white ${
                    selectedUser?.id === user.id ? "bg-[#bb8e25]" : ""
                  }`}
                  onClick={() => {
                    setUserSearch("");
                    setUser(user);
                  }}
                >
                  <div className="tooltip" data-tip="Profile">
                    <img
                      onClick={() => handleOpenModal(user)}
                      className="h-10 w-10 cursor-pointer rounded-full"
                      src={user?.full_path}
                      alt={user?.name}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avtar; // Ensure `avtar` is defined
                      }}
                    />
                  </div>
                  <div className="hidden w-full sm:block">
                    <div className="flex items-center gap-2">
                      <p className="text-sm">{user?.name}</p>
                      <p
                        className={`${
                          user?.user_login === 1
                            ? "text-green-500"
                            : "text-red-500 "
                        } flex items-center gap-2 `}
                      >
                        <span className="relative h-2 w-2 rounded-full bg-current"></span>
                        {/* {selectedUser.user_login} */}
                      </p>
                    </div>
                    <p
                      className={`inline-block w-full text-sm hover:text-white ${
                        selectedUser?.id === user.id
                          ? "text-white "
                          : "text-[#bb8e25] "
                      }`}
                    >
                      {lastMessage.length > 20
                        ? lastMessage.slice(0, 20) + "..." // Slice message to 20 characters and add ellipsis
                        : lastMessage}
                    </p>
                  </div>
                </div>
              );
            })}
          </ul>
        </ul>
      </div>
      <div className="relative w-3/4 bg-white p-0 ">
        {selectedUser ? (
          <>
            <div className="mb-4 flex items-center gap-2 bg-gray-900 p-2 py-4">
              <img
                onClick={() => handleOpenModal(selectedUser)}
                className="h-10 w-10 cursor-pointer rounded-full"
                src={user?.full_path}
                alt={user?.name || "User Avatar"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avtar;
                }}
              />
              <div className="">
                <div className="flex items-center gap-2">
                  <p className=" font-semibold capitalize text-[#bb8e25]">
                    {selectedUser.name}
                  </p>
                  <p
                    className={`${
                      selectedUser?.user_login === 1
                        ? "text-green-500"
                        : "text-red-500 "
                    } flex items-center gap-2 `}
                  >
                    <span className="relative h-2 w-2 rounded-full bg-current"></span>
                    {/* {selectedUser.user_login} */}
                  </p>
                </div>

                <p className="text-sm font-semibold capitalize text-gray-300">
                  {selectedUser.phone_no}
                </p>
              </div>
            </div>
            <div className="hide-scroll-bar relative mt-4 flex h-[65vh] flex-col-reverse overflow-y-auto rounded-lg p-4">
              {messages
                ?.sort(
                  (a, b) => new Date(b.created_at) - new Date(a.created_at)
                )
                ?.map((msg, id) => (
                  <div
                    key={id}
                    className={`mb-2 flex flex-wrap  text-gray-900 ${
                      msg.sender_id !== userInfo?.data?.id
                        ? "justify-start"
                        : "justify-end"
                    }`}
                  >
                    <div
                      className={`bubble flex items-center ${
                        msg.sender_id !== userInfo?.data?.id
                          ? "bubble left"
                          : "bubble right"
                      }`}
                    >
                      <span>{parseMessage(msg.message)}</span>
                    </div>
                  </div>
                ))}
              <div ref={chatEndRef} />
            </div>

            <div className="absolute bottom-0 flex w-full items-center gap-4 p-2">
              <div className="relative w-full">
                <input
                  type="text"
                  disabled={disable} // Correctly use 'disabled'
                  value={newMessage} // Value remains the message text
                  onChange={(e) => setNewMessage(e.target.value)} // Handle input changes
                  className={`input w-full border-gray-300 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    disable ? "pointer-events-none" : ""
                  }`}
                  placeholder={disable ? "Loading..." : "Type your message..."} // Optional placeholder change
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !disable) {
                      sendMessage();
                    }
                  }}
                  style={{
                    backgroundColor: disable ? "#f5f5f5" : "#ffffff", // Ensure the background stays consistent
                    cursor: disable ? "default" : "text", // Prevent the "not-allowed" cursor
                  }}
                />
                {disable && (
                  <span className="loading loading-dots loading-xs absolute right-2 top-1/2 -translate-y-1/2 transform">
                    ...
                  </span>
                )}
              </div>

              <IoSend
                size={48}
                className="cursor-pointer rounded-full bg-blue-500 p-3 text-white"
                onClick={sendMessage}
              />
            </div>
          </>
        ) : (
          <div
            className="flex h-full w-full items-center justify-center bg-cover bg-center"
            style={{ backgroundImage: `url(${chatBotImg})` }}
          ></div>
        )}
      </div>

      <div>
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
}
