import InputField from "components/fields/InputField";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import userResources from "api/userResources";
import Select from "react-tailwindcss-select";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  FaUser,
  FaUserTie,
  FaUsers,
  FaUserShield,
  FaUserSecret,
  FaPhone,
  FaMailBulk,
  FaDollarSign,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import PasswordStrengthIndicator from "components/PasswordStrengthIndicator/PasswordStrengthIndicator";
import { useLocation } from "react-router-dom";

export default function AddUser() {
  const location = useLocation();
  const editData = location?.state?.data;
  console.log(editData, "location");

  const [role, setRole] = useState();
  const [departmentValue, setDepartmentValue] = useState();
  const [branchValue, setBranchValue] = useState();
  const [visiblePassword, setVisiblePassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });
  const [options, setOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(); // State for phone number

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    password_confirmation: "",
    age: "",
    email: "",
    salary: "",
    role_id: role?.value,
    department_id: departmentValue,
    phone_no: phoneNumber,
    branch_id: branchValue,
  });
  const [errors, setErrors] = useState({}); // State for errors

  const handleChangeDepartment = (selectedOption) => {
    setDepartmentValue(selectedOption);

    // Clear the department-related error message
    if (selectedOption) {
      setErrors((prevErrors) => ({ ...prevErrors, department_id: "" })); // setErrors to manage errors
    }
  };

  const passwordValidation = (value) => {
    const strongRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[a-zA-Z\d!@#$%^&*()_+]{8,}$/;
    return strongRegex.test(value);
  };

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validate password and set error message if invalid
    if (name === "password") {
      if (!passwordValidation(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password:
            "Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }
    } else {
      // Clear the error message for other fields
      if (errors[name]) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      }
    }
  };

  const newErrors = {};
  const validateFields = () => {
    const newErrors = {}; // Move newErrors inside the function to reset it each time
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.phone_no) newErrors.phone_no = "Phone Number is required";
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = "Invalid email format.";
    }
    if (!formData.password) newErrors.password = "Password is required";
    if (formData.password !== formData.password_confirmation) {
      newErrors.password_confirmation = "Passwords do not match";
    }
    if (!role) newErrors.role_id = "Role is required";
    if (!branchValue) newErrors.branch_id = "Branch is required";

    // Additional checks based on role
    if (role?.value === Number(process.env.REACT_APP_ROLE_EMPLOYEE)) {
      if (!formData.age) newErrors.age = "Age is required";
      if (!formData.salary) newErrors.salary = "Salary is required";
      if (!departmentValue) newErrors.department_id = "Department is required";
    }

    return newErrors;
  };
  const handleChangePhone = (value) => {
    setPhoneNumber(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone_no: value,
    }));
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, phone_no: "" })); // Clear phone_no error if value is provided
    }
  };

  const handleChangeRole = (selectedOption) => {
    console.log(selectedOption, "selectedOption");

    setRole(selectedOption);

    // Clear the role-related error message
    if (selectedOption?.value) {
      setErrors((prevErrors) => ({ ...prevErrors, role_id: "" })); // Assuming you have setErrors to manage errors
    }

    // Clear age, salary, and department fields when the role changes
    setFormData({
      ...formData,
      age: "",
      salary: "",
      department_id: null,
    });
    setDepartmentValue(null); // Clear the department state
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validate fields
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Stop submission if there are validation errors
    }

    const data = {
      ...formData,
      role_id: role?.value,
      department_id:
        role?.value === Number(process.env.REACT_APP_ROLE_EMPLOYEE)
          ? departmentValue?.value
          : undefined,
      branch_id: branchValue?.value,

      // phone_no: phoneNumber,
    };

    // Remove department_id, age, and salary if role_id is not 2
    if (data.role_id !== Number(process.env.REACT_APP_ROLE_EMPLOYEE)) {
      delete data.department_id;
      delete data.age;
      delete data.salary;
    }

    // Show loading toast
    const loadingToastId = toast.loading("Submitting registration...");

    try {
      // Attempt to register the user
      await userResources.register(data);
      toast.update(loadingToastId, {
        render: "Registration successful!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      // Reset form and phone number after successful registration
      setFormData({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        age: "",
        phone_no: "",
        salary: "",
        role_id: role,
        department_id: departmentValue,
        branch_id: branchValue,
      });
      setRole(null);
      setDepartmentValue(null);
      setBranchValue(null);
      setPhoneNumber(null);
      setErrors({});
    } catch (error) {
      console.error("Registration failed:", error);

      // Determine the error message to show
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "Failed to register. Please try again.";

      // Update the loading toast to show the error message
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    }
  };

  // Function to toggle password visibility
  const togglePasswordVisibility = (field) => {
    setVisiblePassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const getRoleList = async () => {
    try {
      const response = await userResources.role();
      const icons = [FaUser, FaUserTie, FaUsers, FaUserShield, FaUserSecret];

      const formattedRoles = response.data.data.map((role) => ({
        value: role.id,
        label: role.name,
        icon: icons[Math.floor(Math.random() * icons.length)],
      }));

      setOptions(formattedRoles);
    } catch (error) {
      console.log("Failed to fetch roles:", error);
    }
  };
  const getBranch = async () => {
    try {
      const response = await userResources.branch();
      const formattedRoles = response.data.data.map((role) => ({
        value: role.id,
        label: role.name,
      }));
      setBranchOptions(formattedRoles);
    } catch (error) {
      console.error("Error fetching department data:", error);
    } finally {
    }
  };

  const getDepartment = async () => {
    try {
      const response = await userResources.departments();
      const data = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setDepartmentOptions(data);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  // // Effect to set form data if editData is present
  // useEffect(() => {
  //   if (editData) {
  //     setFormData({
  //       name: editData.name || "",
  //       email: editData.email || "",
  //       password: "", // Optionally pre-fill or leave blank
  //       password_confirmation: "",
  //       age: editData.age || "",
  //       salary: editData.salary || "",
  //     });
  //     setRole(editData.role_id); // Assuming role_id is directly usable
  //     setDepartmentValue(editData.department_id); // Assuming department_id is directly usable
  //     setPhoneNumber(editData.phone_no || ""); // Assuming phone number is part of editData
  //   }
  // }, [editData]); // Run this effect when editData changes

  useEffect(() => {
    getRoleList();
    getDepartment();
    getBranch();
  }, []);

  return (
    <div className="mb-16 flex h-full w-full items-center  justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[3vh] w-full max-w-full flex-col items-center bg-white p-4 shadow-md dark:bg-transparent xl:max-w-[600px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-[#bb8e25]">
          Add user
        </h4>
        <p className="mb-2 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        <form onSubmit={onSubmit}>
          <div className="grid grid-cols-1 items-center gap-2 sm:grid-cols-2">
            <InputField
              label="Name"
              name="name"
              value={formData.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              state={errors.name ? "error" : ""}
              errorMessage={errors.name} // Pass the error message here
              placeholder="Name"
              icon={<FaUser />}
            />

            <InputField
              label="Email"
              name="email"
              value={formData.email}
              onChange={(e) => handleInputChange("email", e.target.value)}
              state={errors.email ? "error" : ""}
              errorMessage={errors.email} // Pass the error message here
              placeholder="Email"
              icon={<FaMailBulk />}
            />
          </div>
          <div className="grid grid-cols-1 items-center gap-2 sm:grid-cols-2">
            <div>
              <div className="mb-2 grid grid-cols-1">
                <label
                  htmlFor="phone"
                  className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
                >
                  Phone Number
                </label>
                <div className="relative">
                  <div className="flex">
                    <PhoneInputWithCountrySelect
                      defaultCountry="CA"
                      value={phoneNumber}
                      onChange={(e) => handleChangePhone(e)}
                      placeholder="Enter Phone Number"
                      // required
                      className="w-full rounded-md  border p-2 text-lg focus:outline-none focus:ring-2 dark:!border-white/10"
                    />
                  </div>
                  <div className="absolute right-4 top-4">
                    <FaPhone className="text-gray-900 dark:text-white" />
                  </div>
                </div>
              </div>
              {errors.phone_no && (
                <span className="text-xs text-red-600">{errors.phone_no}</span>
              )}
            </div>
            <div className="mb-2">
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Role
              </label>
              <Select
                value={role}
                onChange={handleChangeRole}
                options={options}
                formatOptionLabel={({ label }, meta = {}) => {
                  const { isSelected } = meta;

                  const Icon =
                    label === "Employee"
                      ? FaUserTie
                      : label === "Admin"
                      ? FaUserShield
                      : FaUser;

                  return (
                    <div
                      className={`block cursor-pointer  select-none truncate rounded px-2 py-2 transition duration-200 ${
                        isSelected
                          ? `bg-gray-900 text-white`
                          : `text-gray-900 hover:bg-gray-900 hover:text-white`
                      }`}
                    >
                      <div className="flex items-center">
                        {Icon && <Icon className="mr-2" />}
                        <span>{label}</span>
                      </div>
                    </div>
                  );
                }}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex text-sm text-gray-900 dark:text-white justify-between px-2 dark:bg-transparent border border-gray-300 dark:!border-white/10 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                      isDisabled ? "bg-gray-200" : "bg-white "
                    }`,
                  menu: "absolute -top-36 z-10 w-full px-0 pl-0 bg-white shadow-lg border rounded py-1 mt-1.5 border-gray-300",
                }}
              />
              {errors.role_id && (
                <span className="text-xs text-red-600">{errors.role_id}</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 items-center gap-2 sm:grid-cols-2">
            <div className="mb-2">
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Password
              </label>
              <div className="relative">
                <input
                  name="password"
                  value={formData.password}
                  onChange={(e) =>
                    handleInputChange("password", e.target.value)
                  }
                  type={visiblePassword.showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  className="border-stroke dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 pl-2 pr-10 text-gray-900 outline-none focus-visible:shadow-none dark:!border-white/10 dark:bg-transparent dark:text-white "
                />
                <span
                  className="absolute right-4 top-3 cursor-pointer"
                  onClick={() => togglePasswordVisibility("showPassword")}
                >
                  {visiblePassword.showPassword ? (
                    <FaEyeSlash className="text-gray-900 dark:text-white" />
                  ) : (
                    <FaEye className="text-gray-900 dark:text-white" />
                  )}
                </span>
              </div>
              {errors.password && (
                <span className="text-sm text-red-600">{errors.password}</span>
              )}
            </div>
            {/* Confirm Password Field */}
            <div className="mb-2">
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  value={formData.password_confirmation}
                  onChange={(e) =>
                    handleInputChange("password_confirmation", e.target.value)
                  }
                  type={
                    visiblePassword.showConfirmPassword ? "text" : "password"
                  }
                  name="password_confirmation"
                  placeholder="Confirm your password"
                  className="border-stroke dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 pl-2 pr-10 text-gray-900 outline-none focus-visible:shadow-none dark:!border-white/10 dark:bg-transparent dark:text-white "
                />
                <span
                  className="absolute right-4 top-3 cursor-pointer"
                  onClick={() =>
                    togglePasswordVisibility("showConfirmPassword")
                  }
                >
                  {visiblePassword.showConfirmPassword ? (
                    <FaEyeSlash className="text-gray-900 dark:text-white" />
                  ) : (
                    <FaEye className="text-gray-900 dark:text-white" />
                  )}
                </span>
              </div>
              {errors.password_confirmation && (
                <span className="text-sm text-red-600">
                  {errors.password_confirmation}
                </span>
              )}
            </div>
          </div>
          <PasswordStrengthIndicator value={formData.password} />

          {/* branch  */}
          <div className="mb-2">
            <label
              className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
              htmlFor="branch"
            >
              Branch
            </label>
            <div className="relative">
              <Select
                value={branchValue}
                id="branch"
                options={branchOptions}
                onChange={(selectedOption) => {
                  setBranchValue(selectedOption);

                  // Clear the department-related error message
                  if (selectedOption) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      branch_id: "",
                    })); // setErrors to manage errors
                  }
                }}
                placeholder="Select branch"
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex text-sm text-gray-900 dark:text-white justify-between dark:!border-white/10 px-2 dark:bg-transparent border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                      isDisabled ? "bg-gray-200" : "bg-white "
                    }`,
                  menu: "absolute top-10 z-10 w-full px-0 pl-0 bg-white shadow-lg border rounded py-1 mt-1.5 border-gray-300",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `bg-gray-900 text-white`
                        : `text-gray-900 hover:bg-gray-900 hover:text-white`
                    }`,
                }}
              />
            </div>
            {errors.branch_id && (
              <span className="text-xs text-red-600">{errors.branch_id}</span>
            )}
          </div>

          {role?.value === Number(process.env.REACT_APP_ROLE_EMPLOYEE) && (
            <>
              {/* Age Field */}
              <div className="grid grid-cols-1 items-center gap-2 sm:grid-cols-2">
                <InputField
                  label="Age"
                  name="age"
                  value={formData.age}
                  onChange={(e) => handleInputChange("age", e.target.value)}
                  state={errors.age ? "error" : ""}
                  errorMessage={errors.age}
                  placeholder="Enter your age"
                  icon={<FaUser />}
                />

                {/* Salary Field */}
                <InputField
                  label="Salary"
                  name="salary"
                  value={formData.salary}
                  onChange={(e) => handleInputChange("salary", e.target.value)}
                  state={errors.salary ? "error" : ""}
                  errorMessage={errors.salary}
                  placeholder="Enter your salary"
                  icon={<FaDollarSign />}
                />
              </div>
              {/* Department Field */}
              <div className="mb-2">
                <label
                  className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
                  htmlFor="department"
                >
                  Department
                </label>
                <div className="relative">
                  <Select
                    value={departmentValue}
                    id="department"
                    options={departmentOptions}
                    onChange={handleChangeDepartment}
                    placeholder="Select department"
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex text-sm text-gray-900 dark:text-white justify-between dark:!border-white/10 px-2 dark:bg-transparent border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${
                          isDisabled ? "bg-gray-200" : "bg-white "
                        }`,
                      menu: "absolute top-10 z-10 w-full px-0 pl-0 bg-white shadow-lg border rounded py-1 mt-1.5 border-gray-300",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                          isSelected
                            ? `bg-gray-900 text-white`
                            : `text-gray-900 hover:bg-gray-900 hover:text-white`
                        }`,
                    }}
                  />
                </div>
                {errors.department_id && (
                  <span className="text-xs text-red-600">
                    {errors.department_id}
                  </span>
                )}
              </div>
            </>
          )}

          <button
            type="submit"
            className="flex w-full items-center justify-center rounded-md bg-[#bb8e25] px-3 py-2 text-base text-white hover:bg-[#bb8e25] focus:outline-none"
          >
            Add User
          </button>
        </form>
      </div>
    </div>
  );
}
