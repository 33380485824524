import userResources from "api/userResources";
import InputField from "components/fields/InputField";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import SubmitButton from "components/SubmitButton/SubmitButton";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { IoIosRemoveCircle, IoMdAddCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-tailwindcss-select";
import { toast } from "react-toastify";

export default function CreateInvoice() {
  const location = useLocation();
  const navigate = useNavigate();
  const [airlinesData, setAirlinesData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [transactionTypeData, setTransactionTypeData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const todayDate = new Date().toISOString().split("T")[0];
  const enquiryData = location.state;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enquiry_id: enquiryData ? enquiryData?.id : "",
      invoice_number: enquiryData ? enquiryData?.invoice_number : "", // Invoice number field
      date: todayDate, // Date field
      supplier_id: "", // Supplier field
      pnr: "", // PNR field
      cost: "", // Cost field
      sold_fare: enquiryData ? enquiryData?.paid_amount : "", // Sold fare field
      amex_card: "", // AMEX card field
      cibc_card: "", // CIBC card field
      td_busness_visa_card: "", // TD Business Visa card field
      bmo_master_card: "", // BMO Master Card field
      rajni_mam: "", // Rajni Mam field
      td_fc_visa: "", // TD FC Visa field
      ch_eq_ue: "", // CH EQ UE field
      ticket_number: "", // Ticket number field
      fnu: "", // FNU field
      airLine_id: enquiryData ? enquiryData?.airLine?.id : "", // Airline ID field
      ticket_status: "", // Ticket status field
      transaction_type_id: enquiryData ? enquiryData?.transactionType?.id : "", // Mode of payment field
      reference_number_of_et: "", // Reference number of ET field
      agent_user_id: enquiryData ? enquiryData?.assignedToUser?.id : "", // Agent name field
      remarks: "", // Remarks field
      // customFields: [], // Custom fields array
    },
  });

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "customFields",
  // });

  const handleChange = (event) => {
    // Clear error when user types in the input field
    clearErrors(event);
  };

  // Submit handler
  const onSubmit = async (data) => {
    // Show a loading toast
    const loadingToastId = toast.loading("Submitting invoice...");
    try {
      const response = await userResources.createInvoice(data);

      if (response.status) {
        // Update toast to success
        toast.update(loadingToastId, {
          render: "Invoice created successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000, // Auto-close after 3 seconds
        });
        reset();
        location.state = null;
        navigate("/admin/enquiry-list");
      }
      console.log("Form Data:", data);
    } catch (error) {
      // Update toast to error
      toast.update(loadingToastId, {
        render: "Error creating invoice. Please try again!",
        type: "error",
        isLoading: false,
        autoClose: 3000, // Auto-close after 3 seconds
      });

      console.error("Error creating invoice:", error);
    }
  };

  // Function to fetch all necessary data
  const fetchData = async () => {
    try {
      // Define an array of API calls
      const apiCalls = [
        {
          method: userResources.adminemployeeslist,
          params: { role_id: Number(process.env.REACT_APP_ROLE_EMPLOYEE) },
          callback: setEmployeeData,
          errorMsg: "User list API call failed:",
        },
        {
          method: userResources.airlines,
          params: { page: 0, size: 99999 },
          callback: setAirlinesData,
          errorMsg: "Airlines API call failed:",
        },
        {
          method: userResources.transactionType,
          // params: {},
          callback: setTransactionTypeData,
          errorMsg: "Error fetching Enquiry Status data:",
        },
        {
          method: userResources.supplier,
          // params: {},
          callback: setSupplierData,
          errorMsg: "Error fetching supplier data:",
        },
      ];

      // Call all APIs simultaneously using Promise.allSettled
      const results = await Promise.allSettled(
        apiCalls.map((api) => api.method(api.params))
      );

      // Process each result
      results.forEach((result, index) => {
        if (result.status === "fulfilled" && result.value?.status) {
          const data = result.value.data.data.map((item) => ({
            value: item.id,
            label: item.name || item.airline_name, // Use item.airline_name if it exists
          }));
          apiCalls[index].callback(data);
        } else {
          console.log(apiCalls[index].errorMsg, result.reason);
        }
      });
    } catch (error) {
      console.log("An unexpected error occurred:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mx-auto max-w-6xl rounded bg-white p-6 shadow dark:bg-gray-900">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-2">
          <InputField
            label="Invoice Number"
            name="invoice_number"
            register={register}
            placeholder="Enter Invoice Number"
            state={errors.invoice_number ? "error" : ""}
            disabled={true}
            extra="w-full"
            rules={{ required: "Invoice Number is required." }}
            errorMessage={errors.invoice_number?.message}
            onChange={() => handleChange("invoice_number")}
          />
          <InputField
            label="Date"
            name="date"
            type="date"
            register={register}
            placeholder="Select Date"
            state={errors.date ? "error" : ""}
            disabled={false}
            extra="w-full"
            rules={{ required: "Date is required." }}
            errorMessage={errors.date?.message}
            onChange={() => handleChange("date")}
          />

          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Supplier
            </label>
            <Select
              {...register(`supplier_id`, {
                required: "Please select a payment method",
              })}
              value={supplierData.find(
                (option) => option.value === watch("supplier_id") // Use watch to get the current value from React Hook Form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("supplier_id", selectedOption?.value || ""); // Update the value using setValue from React Hook Form
                clearErrors("supplier_id");
              }}
              options={supplierData} // Format options
              isDisabled={false}
              isSearchable={true} // Enable search functionality
              placeholder="Select supplier"
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {},
                isSelected: false,
                value: watch("supplier_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.supplier_id && (
              <span className="text-sm text-red-500">
                {errors.supplier_id.message}
              </span>
            )}
          </div>
          <InputField
            label="PNR"
            name="pnr"
            register={register}
            placeholder="Enter PNR"
            state={errors.pnr ? "error" : ""}
            disabled={false}
            extra="w-full"
            rules={{ required: "PNR is required." }}
            errorMessage={errors.pnr?.message}
            onChange={() => handleChange("pnr")}
          />
          <InputField
            label="Cost"
            name="cost"
            type="number"
            register={register}
            placeholder="Enter Cost"
            state={errors.cost ? "error" : ""}
            disabled={false}
            extra="w-full"
            rules={{ required: "Cost is required." }}
            errorMessage={errors.cost?.message}
            onChange={() => handleChange("cost")}
          />
          <InputField
            label="Sold Fare"
            name="sold_fare"
            type="number"
            register={register}
            placeholder="Enter Sold Fare"
            state={errors.sold_fare ? "error" : ""}
            disabled={true}
            extra="w-full"
            rules={{ required: "Sold fare is required." }}
            errorMessage={errors.sold_fare?.message}
            onChange={() => handleChange("sold_fare")}
          />
          <InputField
            label="AMEX Card"
            name="amex_card"
            type="number"
            register={register}
            placeholder="Enter AMEX Card Amount"
            state={errors.amex_card ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("amex_card")}
          />
          <InputField
            label="CIBC Card"
            name="cibc_card"
            type="number"
            register={register}
            placeholder="Enter CIBC Card Amount"
            state={errors.cibc_card ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("cibc_card")}
          />
          <InputField
            label="TD Business Visa Card"
            name="td_busness_visa_card"
            type="number"
            register={register}
            placeholder="Enter TD Business Visa Card Amount"
            state={errors.td_busness_visa_card ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("td_busness_visa_card")}
          />
          <InputField
            label="BMO Master Card"
            name="bmo_master_card"
            type="number"
            register={register}
            placeholder="Enter BMO Master Card Amount"
            state={errors.bmo_master_card ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("bmo_master_card")}
          />
          <InputField
            label="Rajni Mam"
            name="rajni_mam"
            register={register}
            placeholder="Enter Rajni Mam's Details"
            state={errors.rajni_mam ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("rajni_mam")}
          />
          <InputField
            label="TD FC Visa"
            name="td_fc_visa"
            type="number"
            register={register}
            placeholder="Enter TD FC Visa Amount"
            state={errors.td_fc_visa ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("td_fc_visa")}
          />
          <InputField
            label="CH EQ UE"
            name="ch_eq_ue"
            type="number"
            register={register}
            placeholder="Enter CH EQ UE Amount"
            state={errors.ch_eq_ue ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("ch_eq_ue")}
          />
          <InputField
            label="Ticket Number"
            name="ticket_number"
            register={register}
            placeholder="Enter Ticket Number"
            state={errors.ticket_number ? "error" : ""}
            disabled={false}
            extra="w-full"
            rules={{ required: "Ticket number is required." }}
            errorMessage={errors.ticket_number?.message}
            onChange={() => handleChange("ticket_number")}
          />
          <InputField
            label="FNU"
            name="fnu"
            register={register}
            placeholder="Enter FNU"
            state={errors.fnu ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("fnu")}
          />
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Preferred Airline
            </label>
            <Select
              {...register(`airLine_id`, {
                required: "This is required.",
              })}
              value={airlinesData?.find(
                (source) => source.value === watch("airLine_id") // Use watch to get the current value
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("airLine_id", selectedOption?.value || ""); // Update handler using setValue from React Hook Form
                clearErrors("airLine_id");
              }}
              isDisabled={true}
              options={airlinesData} // Format options
              isSearchable={true} // Enable search functionality
              placeholder="Select..."
              classNames={getSelectClassNames({
                isDisabled: true,
                selectProps: {},
                isSelected: false,
                value: watch("airLine_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.airLine_id && (
              <span className="text-sm text-red-500">
                {errors.airLine_id.message}
              </span>
            )}
          </div>
          <InputField
            label="Ticket Status"
            name="ticket_status"
            register={register}
            placeholder="Enter Ticket Status"
            state={errors.ticket_status ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("ticket_status")}
          />

          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Mode of Payment
            </label>
            <Select
              {...register(`transaction_type_id`, {
                required: "Please select a payment method",
              })}
              value={transactionTypeData.find(
                (option) => option.value === watch("transaction_type_id") // Use watch to get the current value from React Hook Form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("transaction_type_id", selectedOption?.value || ""); // Update the value using setValue from React Hook Form
                clearErrors("transaction_type_id");
              }}
              options={transactionTypeData} // Format options
              isDisabled={true}
              isSearchable={true} // Enable search functionality
              placeholder="Select Mode of Payment"
              classNames={getSelectClassNames({
                isDisabled: true,
                selectProps: {},
                isSelected: false,
                value: watch("transaction_type_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.transaction_type_id && (
              <span className="text-sm text-red-500">
                {errors.transaction_type_id.message}
              </span>
            )}
          </div>
          <InputField
            label="Reference Number of ET"
            name="reference_number_of_et"
            register={register}
            placeholder="Enter Reference Number of ET"
            state={errors.reference_number_of_et ? "error" : ""}
            disabled={false}
            extra="w-full"
            onChange={() => handleChange("reference_number_of_et")}
          />
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Agent name
            </label>
            <Select
              {...register(`agent_user_id`, {
                required: "Please select a user to assign this enquiry.",
              })}
              value={employeeData.find(
                (option) => option.value === watch("agent_user_id") // Use watch to get the current value from React Hook Form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("agent_user_id", selectedOption?.value || ""); // Update the value using setValue from React Hook Form
                clearErrors("agent_user_id");
              }}
              options={employeeData} // Format options
              isDisabled={true}
              isSearchable={true} // Enable search functionality
              placeholder="Select Agent name"
              classNames={getSelectClassNames({
                isDisabled: true,
                selectProps: {},
                isSelected: false,
                value: watch("agent_user_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-4 py-2 transition duration-200 ${
                    data.isSelected
                      ? "bg-blue-800 text-gray-100 shadow-lg"
                      : "bg-gray-200 text-gray-900 hover:bg-gray-300"
                  }`}
                >
                  <div className="flex items-center justify-between">
                    <span className="font-semibold">{data.label}</span>
                    <span className="text-sm text-gray-600">
                      ID: {data?.value}
                    </span>
                  </div>
                </li>
              )}
            />
            {errors.agent_user_id && (
              <span className="text-sm text-red-500">
                {errors.agent_user_id.message}
              </span>
            )}
          </div>
          <InputField
            label="Remarks"
            name="remarks"
            register={register}
            placeholder="Enter Remarks"
            state={errors.remarks ? "error" : ""}
            disabled={false}
            extra="w-full"
            isTextArea={true}
            onChange={() => handleChange("remarks")}
          />
          <div className="flex w-full justify-center">
            <SubmitButton
              //   editing={editing}
              //   disabled={disabled}
              add="Submit"
              edit="Update"
              extraClass="btn mt-4 w-full border-0 sm:mt-9"
            />
          </div>
        </div>

        {/* Custom Fields
        <div className="flex items-center justify-between">
          <h2 className="mb-2 mt-6 text-lg font-semibold dark:text-[#bb8e25]">
            Custom Fields
          </h2>
          <IoMdAddCircle
            className="cursor-pointer text-blue-500 sm:text-2xl"
            onClick={() => append({ label: "", name: "" })}
          />
        </div>
        {fields.map((field, index) => (
          <div>
            <div key={field.id} className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <label
                  className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
                  htmlFor={`customFields[${index}].label`}
                >{`Custom Field ${index + 1} Label`}</label>
                <input
                  id={`customFields[${index}].label`}
                  name={`customFields[${index}].label`}
                  type="text"
                  placeholder="Field Label"
                  {...register(`customFields[${index}].label`, {
                    required: "Field label is required",
                  })}
                  onChange={(e) => {
                    clearErrors(`customFields[${index}].label`); // Clear the error
                    setValue(`customFields[${index}].label`, e.target.value); // Update the value
                  }}
                  className={`h-12 w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:outline-none dark:border-white dark:bg-transparent dark:text-gray-200
      ${
        errors.customFields?.[index]?.label
          ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
          : "border border-gray-300 dark:border-gray-50"
      }
       dark:!border-white/10 dark:bg-transparent dark:text-gray-200`}
                />
                {errors.customFields?.[index]?.label && (
                  <span className="error-message text-red-500">
                    {errors.customFields?.[index]?.label?.message}
                  </span>
                )}
              </div>
              <div>
                <label
                  className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
                  htmlFor={`customFields[${index}].name`}
                >{`Custom Field ${index + 1} Name`}</label>
                <input
                  id={`customFields[${index}].name`}
                  name={`customFields[${index}].name`}
                  type="text"
                  placeholder="Field Name"
                  {...register(`customFields[${index}].name`, {
                    required: "Field name is required",
                  })}
                  onChange={(e) => {
                    clearErrors(`customFields[${index}].name`); // Clear the error
                    setValue(`customFields[${index}].name`, e.target.value); // Update the value
                  }}
                  className={`h-12 w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:outline-none dark:border-white dark:bg-transparent dark:text-gray-200  ${
                    errors.customFields?.[index]?.name
                      ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                      : "border border-gray-300 dark:border-gray-100"
                  }
       dark:!border-white/10 dark:bg-transparent dark:text-gray-200`}
                />
                {errors.customFields?.[index]?.name && (
                  <span className="error-message text-red-500">
                    {errors.customFields?.[index]?.name?.message}
                  </span>
                )}
              </div>
            </div>
            <IoIosRemoveCircle
              onClick={() => remove(index)}
              className="mt-2 block cursor-pointer font-bold text-red-500 sm:text-2xl"
            />
          </div>
        ))} */}
      </form>
    </div>
  );
}
