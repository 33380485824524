import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "messages",
  initialState: [], // This will hold all valid messages
  reducers: {
    addMessage: (state, action) => {
      const newMessage = action.payload;

      // Check if the message already exists to avoid duplicates
      const exists = state.some(
        (msg) =>
          msg.message === newMessage.message &&
          msg.sender_id === newMessage.sender_id
      );

      if (!exists) {
        state.push(newMessage); // Add new message if it doesn't exist
      }
    },
    clearMessages: () => {
      return []; // Reset state to an empty array
    },
  },
});

// Export the actions for dispatching
export const { addMessage, clearMessages } = messageSlice.actions;

// Export the reducer for configuring the store
export default messageSlice.reducer;
