import userResources from "api/userResources";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import getStatusTextColor from "components/StatusColorFunction/StatusColorFunction";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { MaterialReactTable } from "material-react-table";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { avtar } from "utils/images";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { Edit } from "@mui/icons-material"; // Importing Material UI icons
import EnquaryHistory from "components/EnquaryHistory/EnquaryHistory";

export default function SearchEnquiry() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(location?.state || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  console.log(history, "history");

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({}); // Reset user profile on close
  };
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
    watch, // Add watch to monitor field changes
  } = useForm();

  // Watch all fields
  const watchedFields = watch();

  // Clear the form-level error when any field changes
  React.useEffect(() => {
    if (errors.form) {
      clearErrors("form");
    }
  }, [watchedFields, errors.form, clearErrors]);

  const onSubmit = async (data) => {
    // Check if at least one field is filled
    const isEmpty = !Object.values(data).some((value) => value.trim() !== "");
    if (isEmpty) {
      setError("form", {
        type: "manual",
        message: "At least one field is required.",
      });
      toast.warning("Please fill at least one field."); // Show toast for empty input
      return;
    }

    clearErrors("form"); // Clear form-level errors

    try {
      // Send data to API
      const response = await userResources.searchEnquiryList(data);

      if (response?.data?.status) {
        // Show server message in toast
        toast.success(response.data.message || "Search successful!");
      } else {
        // Show fallback message if status is false or no message
        toast.warning(response.data.message || "No matching results found.");
      }

      console.log("API Response:", response?.data?.data);
      setData(response?.data?.data);
    } catch (error) {
      // Handle errors
      toast.error(
        error.response?.data?.message || "Failed to fetch search results."
      );
      console.error("API Error:", error);
    }
  };

  // Utility function to format a date as DD-MM-YYYY
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";

    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return "Invalid Date"; // Handle invalid date inputs

    // Format date to DD-MM-YYYY
    const formattedDate = dateObj
      .toLocaleDateString("en-GB")
      .replace(/\//g, "-");
    return formattedDate;
  };

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "email",
        header: "Email Address",
        enableClickToCopy: true,
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },

      {
        accessorKey: "enquirySource",
        header: "Enquiry Source",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "type",
        header: "Type",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },

      {
        accessorKey: "from",
        header: "From",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "to",
        header: "To",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "departure_date",
        header: "Departure Date",

        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
      },
      {
        accessorKey: "return_date",
        header: "Return Date",
        Cell: ({ cell }) => formatDate(cell.getValue()), // Use the utility function
      },
      {
        header: "Traveler Details",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => {
          const adult = row.original.adult ?? "N/A";
          const child = row.original.child ?? "N/A";
          const infant = row.original.infant ?? "N/A";
          const classOfTravel = row.original.class_of_travel ?? "N/A";

          return (
            <div className="whitespace-nowrap">
              <p>Adults: {adult}</p>
              <p>Children: {child}</p>
              <p>Infants: {infant}</p>
              <p>Class: {classOfTravel}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "airLine",
        header: "Preferred Airline",
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const airline = cell.getValue();
          return airline?.airline_name || "N/A";
        },
      },
      {
        accessorKey: "booking_reference",
        header: "Booking Reference",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice",
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "budget",
        header: "Budget",
        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "paid_amount", // This is the new column for budget
        header: "Paid Amount",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "payment_details.pending_amount",
        header: "Pending Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const pendingAmount = row?.original?.payment_details?.pending_amount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (pendingAmount === 0) {
            textColor = "gray";
          } else if (pendingAmount === budget) {
            textColor = "red";
          } else if (pendingAmount < budget) {
            textColor = "orange";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {pendingAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "payment_details.overPaidAmount",
        header: "Over Paid Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const overPaidAmount = row?.original?.payment_details?.overPaidAmount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (overPaidAmount > 0) {
            textColor = "#006400";
          } else {
            textColor = "gray";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {overPaidAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "transactionType",
        header: " Transaction Type",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
      },
      {
        accessorKey: "enquiry_payment_status", // Adjust this to match your actual data structure
        header: "Payment Status",
        filterVariant: "select",
        Cell: ({ cell }) => {
          const paymentStatus = cell.getValue();

          // Helper function to convert status to formatted display text
          const formatPaymentStatus = (status) => {
            const formattedStatus = {
              over_paid: "Over Paid",
              paid: "Paid",
              pending: "Pending",
              not_paid: "Not Paid",
            };
            return formattedStatus[status] || status; // Fallback to original if status is not in the map
          };

          // Function to determine text color based on payment status
          const getPaymentStatusTextColor = (status) => {
            switch (status) {
              case "over_paid":
                return "text-green-800 "; // Green for Paid/Over Paid
              case "paid":
                return "text-green-500"; // Green for Paid/Over Paid
              case "pending":
                return "text-orange-500"; // Orange for Pending
              case "not_paid":
                return "text-red-600"; // Red for Not Paid
              default:
                return "text-gray-600"; // Default gray for unknown status
            }
          };

          return (
            <p>
              <span className={getPaymentStatusTextColor(paymentStatus)}>
                {formatPaymentStatus(paymentStatus)}
              </span>
            </p>
          );
        },
      },
      {
        accessorKey: "admin_payment_status",
        header: "Verify Payment",
        enableClickToCopy: true,
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "admin_payment_status" value
          // Determine background and text colors
          let textColor = "black";
          let value = "";
          if (currentValue === "no_action") {
            textColor = "gray";
            value = "No action";
          } else if (currentValue === "pending") {
            textColor = "orange";
            value = "Pending";
          } else if (currentValue === "approved") {
            textColor = "green";
            value = "Approved";
          }

          return (
            <span
              style={{
                color: textColor,
              }}
            >
              {value || "N/A"}
            </span>
          );
        },
        // enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "enquiryStatus", // Adjust this to match your actual data structure
        header: "Enquiry Status",
        filterVariant: "select",
        Cell: ({ cell }) => {
          const enquiryStatus = cell.getValue();
          const statusName = enquiryStatus?.name || "N/A"; // Use the name property for display
          // Function to determine text color based on status name
          const getEnquiryStatusTextColor = (status) => {
            switch (status) {
              case "New":
                return "text-blue-600"; // Blue for New
              case "Reviewing Options":
                return "text-yellow-600"; // Yellow for Reviewing Options
              case "Awaiting Customer Confirmation":
                return "text-orange-600"; // Orange for Awaiting Customer Confirmation
              case "Booked":
                return "text-green-600"; // Green for Booked
              case "Closed":
                return "text-gray-600"; // Gray for Closed
              default:
                return "text-red-600"; // Default red for unknown status
            }
          };

          return (
            <div className="relative inline-flex items-center gap-1">
              <span className="group relative ml-2 cursor-pointer">
                {/* Info Icon */}
                <AiOutlineInfoCircle className="text-gray-600 hover:text-gray-800" />
                {/* Tooltip */}
                <span className="absolute -top-8 left-0 hidden w-48 bg-gray-700 p-2 text-xs text-white group-hover:block">
                  <strong>Status Info:</strong>
                  <br />
                  ID: {enquiryStatus?.id || "N/A"}
                  <br />
                  Status: {statusName}
                </span>
              </span>
              <span className={getEnquiryStatusTextColor(statusName)}>
                {statusName}
              </span>
            </div>
          );
        },
      },

      {
        accessorKey: "remark",
        header: "Remarks",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ cell }) => cell.getValue() || "N/A",
      },
      {
        accessorKey: "created_at", // Adjust this to match your actual data structure
        header: "Created Date",

        Cell: ({ cell }) => {
          const createdAt = new Date(cell.getValue());
          const date = createdAt.toLocaleDateString(); // Get the date portion
          const time = createdAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
                  "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
                  "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "updated_at", // Adjust this to match your actual data structure
        header: "Updated Date",
        Cell: ({ cell }) => {
          const updatedAt = new Date(cell.getValue());
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
                  "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
                  "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "assignedToUser",
        header: "Assigned To",
        filterVariant: "select",
        Cell: ({ cell }) => {
          const assignedUser = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              {assignedUser ? (
                <div className="tooltip" data-tip="Profile">
                  <img
                    onClick={() => handleOpenModal(assignedUser)} // Wrap in an arrow function for passing data
                    className="h-10 w-10 cursor-pointer rounded-full"
                    src={assignedUser?.full_path}
                    alt={assignedUser?.name}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avtar; // Default avatar
                    }}
                  />
                </div>
              ) : (
                "N/A"
              )}
              {assignedUser && (
                <div>
                  <p>{assignedUser?.name}</p>
                  <p>{assignedUser?.phone_no}</p>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "createdByUser",
        header: "Created By",
        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={assignedBy?.full_path}
                  alt={assignedBy?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p>{assignedBy?.name}</p>
                <p>{assignedBy?.phone_no}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "updatedByUser",
        header: "updated By",
        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();
          return (
            <div className="flex items-center gap-2">
              {assignedBy ? (
                <>
                  <div className="tooltip" data-tip="Profile">
                    <img
                      onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function for passing data
                      className="h-10 w-10 cursor-pointer rounded-full"
                      src={assignedBy?.full_path}
                      alt={assignedBy?.name || "User Avatar"}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avtar; // Default avatar
                      }}
                    />
                  </div>
                  <div>
                    <p>{assignedBy?.name}</p>
                    <p>{assignedBy?.phone_no}</p>
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: true, // Disable filter for this column
        Cell: ({ row, cell }) => {
          const status = cell.getValue();
          const statusName = status;

          return (
            <div className="relative inline-flex items-center gap-1">
              <p className={getStatusTextColor(statusName)}>{statusName}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Edit
              className="cursor-pointer text-blue-500"
              onClick={() => {
                const { package_type } = row.original; // Destructure for cleaner access
                const rowDataWithNavigateTo = {
                  ...row.original,
                  //   NavigateTo: api,
                  navigateToAllList: true,
                };
                console.log(rowDataWithNavigateTo, "rowDataWithNavigateTo");

                if (package_type === "multi_city") {
                  navigate(`/admin/create-enquiry/flight/multi-city`, {
                    state: rowDataWithNavigateTo,
                  });
                } else if (package_type === "return_way") {
                  navigate(`/admin/create-enquiry/flight/round-trip`, {
                    state: rowDataWithNavigateTo,
                  });
                } else if (package_type === "one_way") {
                  navigate(`/admin/create-enquiry/flight/oneway`, {
                    state: rowDataWithNavigateTo,
                  });
                }
              }}
            />
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setHistory(data);
  }, [data]);

  return (
    <div className="bg-gray-900 py-6 text-white sm:mx-4 ">
      <form onSubmit={handleSubmit(onSubmit)} className="mb-4 grid gap-6 px-2">
        {/* Input Fields */}
        <div className="grid grid-cols-1 items-end gap-4 sm:grid-cols-2 md:grid-cols-4">
          <div>
            <label
              htmlFor="email"
              className="mb-2 block text-sm font-medium text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              placeholder="Enter email"
              className="w-full rounded border border-gray-300 bg-white p-2 text-gray-800"
              {...register("email")}
            />
          </div>
          <div>
            <label
              htmlFor="phone_number"
              className="mb-2 block text-sm font-medium text-white"
            >
              Phone Number
            </label>
            <input
              type="text"
              id="phone_number"
              placeholder="Enter phone number"
              className="w-full rounded border border-gray-300 bg-white p-2 text-gray-800"
              {...register("phone_number")}
            />
          </div>
          <div>
            <label
              htmlFor="booking_reference"
              className="mb-2 block text-sm font-medium text-white"
            >
              Booking Reference
            </label>
            <input
              type="text"
              id="booking_reference"
              placeholder="Enter booking reference"
              className="w-full rounded border border-gray-300 bg-white p-2 text-gray-800"
              {...register("booking_reference")}
            />
          </div>
          <button
            type="submit"
            className="w-full rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
          >
            Search Enquiry
          </button>
        </div>

        {/* Form-Level Error Message */}
        {errors.form && (
          <p className="mt-2 text-sm text-red-500">{errors.form.message}</p>
        )}
      </form>
      <section className="mx-2 mt-0 sm:mt-5">
        <ScrollToTopOnRouteChange />
        {/* Modal for User Info */}
        <div className="">
          {isModalOpen && (
            <UserInfoModal user={userProfile} onClose={handleCloseModal} />
          )}
        </div>

        {data.length > 0 && (
          <section>
            <MaterialReactTable
              columns={columns}
              data={data}
              state={{
                isLoading,
                showProgressBars: isLoading,
              }}
              enableColumnFilters={false} // Pass the current visibility state
              initialState={{ showColumnFilters: true }}
              enableSorting={false}
              enableColumnOrdering={false}
              enableRowActions
              enablePagination
              manualPagination
              isLoading={isLoading}
              muiTablePaginationProps={{
                showFirstButton: false,
                showLastButton: false,
              }}
              enableGlobalFilter={false} // Keep this true to allow global filtering
              // editingMode="modal" // Ensure this is correctly set
              // enableEditing // This should also be true
              renderRowActions={({ row }) => (
                <div className="flex flex-nowrap gap-4">
                  <button
                    className="text-xl text-primary"
                    onClick={() =>
                      window.open(
                        `mailto:${row.original.email}?subject=Hello ${row.original.customer_name}!`
                      )
                    }
                  >
                    <FaMailBulk />
                  </button>
                  <button
                    className="text-xl text-success"
                    onClick={() =>
                      window.open(
                        `https://wa.me/${row.original.phone_number.replace(
                          /\D/g,
                          ""
                        )}?text=Hello ${row.original.customer_name}!`
                      )
                    }
                  >
                    <FaWhatsapp />
                  </button>
                </div>
              )}
              renderDetailPanel={({ row }) => (
                <div style={{ padding: "6px" }} className="max-w-7xl">
                  {row.original.packages && row.original.packages.length > 0 ? (
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <thead>
                        <tr>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            Enquiry ID
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            From
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            To
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            Departure Date
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            Created At
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            Package Type
                          </th>
                          <th
                            style={{ padding: "8px", border: "1px solid #ddd" }}
                          >
                            Updated At
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.original.packages.map((pkg) => (
                          <tr key={pkg.enquiry_id}>
                            {" "}
                            {/* Use a unique identifier for the key */}
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {pkg.enquiry_id}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {pkg.from}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {pkg.to}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {pkg.departure_date}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {new Date(pkg.created_at).toLocaleString()}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {pkg.package_type}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                border: "1px solid #ddd",
                              }}
                            >
                              {new Date(pkg.updated_at).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No packages available.</p> // Optional message when no packages are present
                  )}
                </div>
              )}
            />
            <section className="  ">
              {history?.length > 0 ? (
                history.map((e, index) => (
                  <div key={index} className="my-5">
                    <EnquaryHistory History={e?.children} />
                  </div>
                ))
              ) : (
                <p className="my-5 text-center text-gray-500">
                  No history available.
                </p>
              )}
            </section>
          </section>
        )}
      </section>
    </div>
  );
}
