import React from "react";

export default function Logo({ logo1, logo2, logo3, width }) {
  return (
    <section>
      <div className="flex flex-col items-center">
        <img
          src={logo1}
          alt=""
          className={`${width?.logo1 ? width?.logo1 : "w-16"}`}
        />
        <img
          src={logo2}
          alt=""
          className={`${width?.logo2 ? width?.logo2 : "w-44"}`}
        />
        <img
          src={logo3}
          alt=""
          className={`${width?.logo3 ? width?.logo3 : "w-60"}`}
        />
      </div>
    </section>
  );
}
