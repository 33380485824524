import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import Logout from "components/Logout/Logout";
import katariatravels from "utils/images";
import { FaUser } from "react-icons/fa";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import GoBackButton from "components/GoBackButton/GoBackButton";
import { toast } from "react-toastify";
import userResources from "api/userResources";
import NavbarSearchAnyEnquirys from "components/Home/NavbarSearchAnyEnquirys/NavbarSearchAnyEnquirys";
import { avtar } from "utils/images";
import { clearMessages } from "../../redux/messageSlice";

const Navbar = (props) => {
  const params = useParams();
  const chatId = params["*"];
  const messages = useSelector((state) => state?.messages);
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [notificationData, setNotificationData] = useState([]);
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const user = userInfo.data;
  const [userData, setUserData] = useState([]);

  // console.log(user, "userInfo");

  const handleOpenModal = (user) => {
    setUserProfile(user);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUserProfile({}); // Reset user profile on close
  };

  const getUserList = async () => {
    const params = {
      page: 0,
      size: 9999,
    };
    try {
      const response = await userResources.userList(params);
      setUserData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching User's list:", error);
    }
  };

  const getNotifications = async () => {
    try {
      const response = await userResources.notification();
      console.log(response, "notification");
      if (response.data.success) {
        setNotificationData(response?.data?.data);
      }
      if (response.data.success) {
        const notifications = response?.data?.data
          .map((item) => item?.data?.message)
          ?.filter(Boolean); // Return messages and filter out any undefined values
        if (notifications.length > 0) {
          // Join all the messages as a single string and show them in the toast
          // toast.success(notifications.join(", "));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearMessages = () => {
    if (messages.length > 0) {
      dispatch(clearMessages());
      toast.success("Messages cleared successfully!"); // Show success toast
    } else {
      return;
    }
  };

  useEffect(() => {
    // Ensure the messages array is not empty
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1]; // Get the last message
      // Check if the last message's senderId matches any userData.id
      const sender = userData.find((user) => user.id === lastMessage.sender_id);
      if (
        sender &&
        chatId !== "chat-app" &&
        userInfo?.data?.id !== sender?.id
      ) {
        toast.success(
          <div>
            <div style={{ fontSize: "14px", fontWeight: "bolder" }}>
              {sender.name}
            </div>
            <div style={{ fontSize: "12px", color: "#888" }}>
              {lastMessage.message}
            </div>
          </div>
        );
      }
    }
  }, [messages]); // Dependencies include messages, userData, and data.message
  console.log(chatId, "p");

  useEffect(() => {
    // Run getNotifications every 5 seconds
    getNotifications();
    getUserList();
    // const interval = setInterval(() => {
    // }, 5000);

    // // Cleanup the interval on component unmount
    // return () => clearInterval(interval);
  }, []);

  return (
    <section>
      <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
        <div className="ml-[6px]">
          <div className="h-6 w-[224px] pt-1">
            <a
              className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              href=" "
            >
              Pages
              <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                {" "}
                /{" "}
              </span>
            </a>
            <Link
              className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
              to="#"
            >
              {brandText}
            </Link>
          </div>
          <p className="shrink text-[24px] capitalize text-navy-700 dark:text-white sm:text-[33px]">
            <Link
              to="#"
              className="font-bold capitalize  text-navy-700 dark:text-[#bb8e25]"
            >
              {brandText}
            </Link>
          </p>
        </div>

        <div className="visible  mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none sm:hidden md:w-[465px] md:flex-grow-0 md:gap-1 xl:w-[465px] xl:gap-2">
          <NavbarSearchAnyEnquirys />
        </div>

        <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[465px] md:flex-grow-0 md:gap-1 xl:w-[465px] xl:gap-2">
          <div className="hidden h-full w-[100px] items-center overflow-hidden rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white sm:flex xl:w-[225px]">
            <NavbarSearchAnyEnquirys />
          </div>
          <span
            className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
            onClick={onOpenSidenav}
          >
            <FiAlignJustify className="h-5 w-5" />
          </span>
          {/* start Notification */}
          <Dropdown
            button={
              <div className="relative">
                <p className="cursor-pointer ">
                  <IoMdNotificationsOutline
                    className={`h-4 w-4 ${
                      notificationData?.length > 0
                        ? "text-red-500"
                        : "text-gray-600 dark:text-white"
                    }`}
                  />
                </p>
                <p className="absolute -top-2 left-3 text-xs font-semibold text-red-500">
                  {notificationData?.length > 0 ? notificationData?.length : ""}
                </p>
              </div>
            }
            animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
            children={
              <div className=" mt-5 flex w-full flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[360px]">
                <div className="flex items-center justify-between">
                  <p className="text-base font-bold text-navy-700 dark:text-white">
                    {notificationData?.length > 0
                      ? "Notification"
                      : "No notification"}
                  </p>
                  {notificationData?.length > 0 ? (
                    <p
                      className="text-sm font-bold text-red-500 "
                      onClick={handleClearMessages}
                    >
                      Clear
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="hide-scroll-bar h-96 overflow-scroll">
                  {notificationData.map((item, index) => {
                    return (
                      <button
                        className="my-2 flex w-full items-center"
                        key={index}
                      >
                        <div className="flex h-12 w-14 items-center justify-center rounded-full bg-gradient-to-b from-brandLinear to-brand-500 py-4 text-2xl text-white">
                          <BsArrowBarUp />
                        </div>
                        <div className="ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-sm">
                          <div className="flex justify-between">
                            <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-[#bb8e25] ">
                              {item?.data?.customer_name}
                            </p>
                            <p className="mb-1 text-left text-xs text-gray-900 dark:text-white">
                              {item?.data?.followed_up_at}
                            </p>
                          </div>
                          <p className="font-base text-left text-xs text-gray-900 dark:text-white">
                            {item?.data?.message}
                          </p>
                        </div>
                      </button>
                    );
                  })}
                  {messages.map((item) => {
                    // Find the corresponding user in the userData array
                    const user = userData?.find(
                      (user) => user.id === item.sender_id
                    );

                    return (
                      <div key={item.sender_id}>
                        {/* If user exists and has an image, display the image */}
                        <div className="flex items-center gap-4">
                          <div className="my-1">
                            <img
                              src={user?.full_path} // Assuming 'image' is the key for the image URL in userData
                              alt={avtar} // Optional: Use the user's name as alt text
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = avtar; // Make sure `avtar` is defined
                              }}
                              className="h-11 w-11 rounded-full" // Tailwind CSS for a circular image
                            />
                          </div>
                          <div>
                            <p className="text-base font-bold text-gray-900 dark:text-[#bb8e25]">
                              {user?.name}
                            </p>
                            <p className="text-xs text-gray-900 dark:text-white">
                              {item?.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
            classNames={
              "py-2 top-4 -left-[70px] sm:-left-[230px] md:-left-[240px] w-max"
            }
          />
          {/* start Kataria PRO */}
          {/* <Dropdown
            button={
              <p className="cursor-pointer">
                <IoMdInformationCircleOutline className="h-4 w-4 text-gray-600 dark:text-white" />
              </p>
            }
            children={
              <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div
                  style={{
                    backgroundImage: `url(${katariatravels})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  className="mb-2 aspect-video w-full rounded-lg"
                />
              </div>
            }
            classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
            animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
          /> */}
          <div
            className="cursor-pointer text-gray-600"
            onClick={() => {
              if (darkmode) {
                document.body.classList.remove("dark");
                setDarkmode(false);
              } else {
                document.body.classList.add("dark");
                setDarkmode(true);
              }
            }}
          >
            {darkmode ? (
              <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
            ) : (
              <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
            )}
          </div>
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img
                className="h-10 w-10 rounded-full"
                src={user?.full_path}
                alt={avtar} // Optional: Use the user's name as alt text
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = avtar; // Make sure `avtar` is defined
                }}
              />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex flex-col  gap-2">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      Hey, {user?.name}👋
                    </p>{" "}
                    <div className="flex items-center gap-2">
                      <FaUser className="text-gray-800 dark:text-white" />
                      <p
                        className="cursor-pointer font-semibold text-gray-400 hover:text-gray-800 dark:hover:text-white"
                        onClick={() => handleOpenModal(user)}
                      >
                        Profile
                      </p>
                    </div>
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  {/* <a
                  href=" "
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  href=" "
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Newsletter Settings
                </a> */}
                  <Logout />
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
          <GoBackButton />
        </div>
      </nav>
      {/* Modal for User Info */}
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>
    </section>
  );
};

export default Navbar;
