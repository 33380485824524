import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { AiOutlineInfoCircle } from "react-icons/ai";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { avtar } from "utils/images";
import { useLocation } from "react-router-dom";
import getStatusTextColor from "components/StatusColorFunction/StatusColorFunction";
import GoBackButton from "components/GoBackButton/GoBackButton";
export default function EnquaryHistory({ History }) {
  const location = useLocation();
  const historydata = History || location.state;
  console.log(historydata, "history");

  const [data, setData] = useState(historydata?.reverse() || []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setUserProfile({}); // Reset user profile on close
  };

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        Cell: ({ row }) => {
          const parentId = row.original.parent_id;
          const id = row.original.id;
          const formattedId = id ? id.toString().padStart(3, "0") : "001"; // Default 001 if ID is empty
          return `ENQ${parentId}-${formattedId}`;
        },
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "title" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.title
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "customer_name" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.customer_name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "email",
        header: "Email Address",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "emal" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.email
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "phone_number" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.phone_number
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "enquirySource",
        header: "Enquiry Source",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue()?.name; // current row's "enquirySource" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.enquirySource?.name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "type",
        header: "Type",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "type" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.type
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },

      {
        accessorKey: "from",
        header: "From",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "from" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the first or last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1 &&
            currentRowIndex > 0
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.from
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "to",
        header: "To",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "to" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.to
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "departure_date",
        header: "Departure Date",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "departure_date" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.departure_date
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
      },
      {
        accessorKey: "return_date",
        header: "Return Date",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "return_date" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.return_date
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      {
        header: "Traveler Details",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row, table }) => {
          const currentRowIndex = row.index;

          const adult = row.original.adult ?? "N/A";
          const child = row.original.child ?? "N/A";
          const infant = row.original.infant ?? "N/A";
          const classOfTravel = row.original.class_of_travel ?? "N/A";

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextRow =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
              : null;

          // Compare current row's details with the next row's details
          const isDifferent =
            nextRow &&
            (nextRow.adult !== row.original.adult ||
              nextRow.child !== row.original.child ||
              nextRow.infant !== row.original.infant ||
              nextRow.class_of_travel !== row.original.class_of_travel) &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <div
              className={`whitespace-nowrap ${
                isDifferent ? "text-purple-500" : ""
              }`}
            >
              <p>Adults: {adult}</p>
              <p>Children: {child}</p>
              <p>Infants: {infant}</p>
              <p>Class: {classOfTravel}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "airLine",
        header: "Preferred Airline",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue()?.airline_name || "N/A";
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.airLine
                  ?.airline_name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue}
            </span>
          );
        },
      },
      {
        accessorKey: "booking_reference",
        header: "Booking Reference",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "booking_reference" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.booking_reference
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "invoice_number" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.invoice_number
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "budget",
        header: "Budget",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue() || "N/A";
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.budget
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "paid_amount",
        header: "Paid Amount",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue() || "N/A";
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.paid_amount
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue}
            </span>
          );
        },
      },
      {
        accessorKey: "payment_details.pending_amount",
        header: "Pending Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const pendingAmount = row?.original?.payment_details?.pending_amount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (pendingAmount === 0) {
            textColor = "gray";
          } else if (pendingAmount === budget) {
            textColor = "red";
          } else if (pendingAmount < budget) {
            textColor = "orange";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {pendingAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "payment_details.overPaidAmount",
        header: "Over Paid Amount",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row }) => {
          const overPaidAmount = row?.original?.payment_details?.overPaidAmount; // Get pending amount
          const budget = row.original.budget; // Get budget amount

          // Determine background and text colors
          let textColor = "black";

          if (overPaidAmount > 0) {
            textColor = "#006400";
          } else {
            textColor = "gray";
          }

          return (
            <div
              style={{
                color: textColor,
                borderRadius: "0.25rem",
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {overPaidAmount?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "transactionType",
        header: "Transaction Type",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue()?.name; // current row's "transactionType" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.transactionType?.name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
        // Enable filter for this column
        filter: "fuzzy", // You can choose your filter method here
      },
      {
        accessorKey: "enquiry_payment_status", // Adjust this to match your actual data structure
        header: "Payment Status",
        Cell: ({ cell, row, table }) => {
          // Helper function to convert status to formatted display text
          const formatPaymentStatus = (status) => {
            const formattedStatus = {
              over_paid: "Over Paid",
              paid: "Paid",
              pending: "Pending",
              not_paid: "Not Paid",
            };
            return formattedStatus[status] || status; // Fallback to original if status is not in the map
          };
          const currentValue = cell.getValue() || "N/A"; // Ensure we get the name, or "N/A" if missing
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.enquiry_payment_status
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            nextValue !== null &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {formatPaymentStatus(currentValue)}
            </span>
          );
        },
      },
      {
        accessorKey: "admin_payment_status",
        header: "Verify Payment",
        enableClickToCopy: true,
        // enableColumnFilter: false, // Enable filter for this column
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "admin_payment_status" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.admin_payment_status
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          // Determine background and text colors
          let textColor = "black";
          let value = "";
          if (currentValue === "no_action") {
            textColor = "gray";
            value = "No action";
          } else if (currentValue === "pending") {
            textColor = "orange";
            value = "Pending";
          } else if (currentValue === "approved") {
            textColor = "green";
            value = "Approved";
          }

          return (
            <span
              className={isDifferent ? "text-purple-500" : ""}
              style={{
                color: textColor,
              }}
            >
              {value || "N/A"}
            </span>
          );
        },
      },
      {
        accessorKey: "enquiryStatus", // Adjust this to match your actual data structure
        header: "Enquiry Status",
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue()?.name || "N/A"; // Ensure we get the name, or "N/A" if missing
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.enquiryStatus?.name
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue}
            </span>
          );
        },
      },

      {
        accessorKey: "assignedToUser",
        header: "Assigned To",

        // Enable filter for this column
        Cell: ({ cell, row, table }) => {
          const assignedUser = cell.getValue();
          const currentRowIndex = row.index;
          console.log(row?.original?.assignedToUser?.name);

          if (!assignedUser) {
            return null; // Handle case where assignedUser is undefined or null
          }

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.assignedToUser
              : null;

          // Check if the current value is different from the next row's value
          const isDifferentName =
            nextValue?.name && nextValue?.name !== assignedUser?.name;
          const isDifferentPhone =
            nextValue?.phone_no &&
            nextValue?.phone_no !== assignedUser?.phone_no;

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(assignedUser)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={assignedUser?.full_path}
                  alt={assignedUser?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p className={isDifferentName ? "text-purple-500" : ""}>
                  {assignedUser?.name}
                </p>
                <p className={isDifferentPhone ? "text-purple-500" : ""}>
                  {assignedUser?.phone_no}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "remark",
        header: "Remarks",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ cell, row, table }) => {
          const currentValue = cell.getValue(); // current row's "remark" value
          const currentRowIndex = row.index;

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original?.remark
              : null;

          // Check if the current value is different from the next row's value, but don't change color for the last row
          const isDifferent =
            nextValue !== currentValue &&
            currentRowIndex < table.getRowModel().rows.length - 1;

          return (
            <span className={isDifferent ? "text-purple-500" : ""}>
              {currentValue || "N/A"}
            </span>
          );
        },
      },
      // {
      //   accessorKey: "created_at", // Adjust this to match your actual data structure
      //   header: "Created Date",
      //   Cell: ({ cell, row, table }) => {
      //     const createdAt = new Date(cell.getValue()); // Parse the date from the cell value
      //     const date = createdAt.toLocaleDateString(); // Get the date portion
      //     const time = createdAt.toLocaleTimeString(); // Get the time portion
      //     const currentRowIndex = row.index;
      //     const totalRows = table.getRowModel().rows.length;

      //     // Check if there is a next row to compare against
      //     const nextValue =
      //       currentRowIndex < totalRows - 1
      //         ? table.getRowModel().rows[currentRowIndex + 1]?.original
      //             ?.created_at
      //         : null;

      //     // If there is no next row, do not apply any styling
      //     if (!nextValue)
      //       return (
      //         <div>
      //           <p className="font-semibold">
      //             Date: <span className="font-light">{date}</span>
      //           </p>
      //           <p className="font-semibold">
      //             Time: <span className="font-light">{time}</span>
      //           </p>
      //         </div>
      //       );

      //     // Extract date and time from the next row's value
      //     const nextDate = nextValue
      //       ? new Date(nextValue).toLocaleDateString()
      //       : null;
      //     const nextTime = nextValue
      //       ? new Date(nextValue).toLocaleTimeString()
      //       : null;

      //     // Compare the date and time separately
      //     const isDateDifferent = date !== nextDate;
      //     const isTimeDifferent = time !== nextTime;

      //     return (
      //       <div>
      //         <p
      //           className={`font-semibold ${
      //             isDateDifferent ? "text-purple-500" : ""
      //           }`}
      //         >
      //           Date: <span className="font-light">{date}</span>
      //         </p>
      //         <p
      //           className={`font-semibold ${
      //             isTimeDifferent ? "text-purple-500" : ""
      //           }`}
      //         >
      //           Time: <span className="font-light">{time}</span>
      //         </p>
      //       </div>
      //     );
      //   },
      // },

      {
        accessorKey: "updated_at", // Adjust this to match your actual data structure
        header: "Updated Date",
        Cell: ({ cell, row, table }) => {
          const updatedAt = new Date(cell.getValue()); // Parse the date from the cell value
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion
          const currentRowIndex = row.index;
          const totalRows = table.getRowModel().rows.length;

          // Check if there is a next row to compare against
          const nextValue =
            currentRowIndex < totalRows - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.updated_at
              : null;

          // If there is no next row, do not apply any styling
          if (!nextValue)
            return (
              <div>
                <p className="font-semibold">
                  Date: <span className="font-light">{date}</span>
                </p>
                <p className="font-semibold">
                  Time: <span className="font-light">{time}</span>
                </p>
              </div>
            );

          // Extract date and time from the next row's value
          const nextDate = nextValue
            ? new Date(nextValue).toLocaleDateString()
            : null;
          const nextTime = nextValue
            ? new Date(nextValue).toLocaleTimeString()
            : null;

          // Compare the date and time separately
          const isDateDifferent = date !== nextDate;
          const isTimeDifferent = time !== nextTime;

          return (
            <div>
              <p
                className={`font-semibold ${
                  isDateDifferent ? "text-purple-500" : ""
                }`}
              >
                Date: <span className="font-light">{date}</span>
              </p>
              <p
                className={`font-semibold ${
                  isTimeDifferent ? "text-purple-500" : ""
                }`}
              >
                Time: <span className="font-light">{time}</span>
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "updatedByUser",
        header: "Updated By",
        Cell: ({ cell, row, table }) => {
          const updatedBy = cell.getValue();
          const currentRowIndex = row.index;

          if (!updatedBy) {
            return null; // Handle case where updatedBy is undefined or null
          }

          // Check if there is a next row to compare against and ensure it's not the last row
          const nextValue =
            currentRowIndex < table.getRowModel().rows.length - 1
              ? table.getRowModel().rows[currentRowIndex + 1]?.original
                  ?.updatedByUser
              : null;

          // Check if the current value is different from the next row's value
          const isDifferentName =
            nextValue?.name && nextValue?.name !== updatedBy?.name;
          const isDifferentPhone =
            nextValue?.phone_no && nextValue?.phone_no !== updatedBy?.phone_no;

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(updatedBy)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={updatedBy?.full_path}
                  alt={updatedBy?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p className={isDifferentName ? "text-purple-500" : ""}>
                  {updatedBy?.name}
                </p>
                <p className={isDifferentPhone ? "text-purple-500" : ""}>
                  {updatedBy?.phone_no}
                </p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "followed_up_at", // Adjust this to match your actual data structure
        header: "Followed Up Date",
        Cell: ({ cell }) => {
          const value = cell.getValue(); // Get the cell value
          if (!value) {
            // If the value is null or undefined, show "No follow-up set"
            return (
              <div className="flex flex-col">
                <p className="italic text-gray-500">No follow-up set</p>
              </div>
            );
          }

          // If a valid date exists, format and display it
          const updatedAt = new Date(value);
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p className="font-semibold">
                Date: <span className="font-light">{date}</span>
              </p>
              <p className="font-semibold">
                Time: <span className="font-light">{time}</span>
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableColumnFilter: false, // Disable filter for this column
        Cell: ({ row, cell }) => {
          const status = cell.getValue();
          const statusName = status;

          return (
            <div className="relative inline-flex items-center gap-1">
              <p className={getStatusTextColor(statusName)}>{statusName}</p>
            </div>
          );
        },
      },
    ],
    [data]
  );

  useEffect(() => {
    if (historydata) {
      setData(historydata.reverse()); // Ensure proper state update
    }
  }, [historydata]);

  return (
    <section className="">
      <ScrollToTopOnRouteChange />
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>

      <div
        className={`${History ? "" : "h-[100vh]"} bg-white dark:bg-gray-900`}
      >
        <div className="flex items-center justify-between p-5 font-semibold">
          <h2 className="text-3xl text-gray-900 dark:text-[#bb8e25]">
            History
          </h2>
          {!History && <GoBackButton />}
        </div>
        <div>
          {historydata.map((e, index) => {
            <h2 className="my-5 text-2xl" key={index}>
              Search Enquiry History {e?.parent_id}
            </h2>;
          })}
        </div>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnFilters={false} // Disable column filters
          initialState={{
            showColumnFilters: false, // Hide column filters
            pagination: { pageSize: data.length }, // Set the page size to the total data length
          }}
          enableColumnOrdering={false} // Disable column ordering
          enableGlobalFilter={false} // Disable global filter
          enablePagination={false} // Disable pagination feature
          manualPagination={true} // Ensure pagination is controlled manually
          renderDetailPanel={({ row }) => (
            <div style={{ padding: "6px" }} className="max-w-7xl">
              {row.original.packages && row.original.packages.length > 0 ? (
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        Enquiry ID
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        From
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        To
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        Departure Date
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        Created At
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        Package Type
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                        Updated At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {row.original.packages.map((pkg) => (
                      <tr key={pkg.enquiry_id}>
                        {" "}
                        {/* Use a unique identifier for the key */}
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {pkg.enquiry_id}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {pkg.from}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {pkg.to}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {pkg.departure_date}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {new Date(pkg.created_at).toLocaleString()}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {pkg.package_type}
                        </td>
                        <td
                          style={{ padding: "8px", border: "1px solid #ddd" }}
                        >
                          {new Date(pkg.updated_at).toLocaleString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No packages available.</p> // Optional message when no packages are present
              )}
            </div>
          )}
        />
      </div>
    </section>
  );
}
