import React from "react";

export default function SubmitButton({
  editing,
  disabled,
  add,
  edit,
  onClick,
  extraClass,
}) {
  return (
    <button
      type="submit"
      disabled={disabled}
      onClick={onClick ? onClick : undefined} // Conditionally add onClick handler
      className={` p-2 text-white focus:outline-none ${
        extraClass ? extraClass : "w-full rounded-lg"
      } ${
        disabled
          ? "cursor-not-allowed bg-gray-400"
          : "bg-gray-900 hover:bg-gray-700 focus:ring focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-500"
      }`}
    >
      {editing ? edit : add}
    </button>
  );
}
