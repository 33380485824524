import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./assets/css/App.css";
import App from "./App";
import { toast, ToastContainer } from "react-toastify";
import store from "./redux/Store";
import { persistor } from "./redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import DisableScrollForNumberInputs from "components/DisableScrollForNumberInputs/DisableScrollForNumberInputs";
import Pusher from "pusher-js"; // Ensure Pusher is correctly imported
import { selectUserInfo } from "./redux/userSlice";
import { addMessage } from "./redux/messageSlice";
import notificationSound from "./assets/Sound/notification.wav";

const RootComponent = () => {
  const userInfo = useSelector(selectUserInfo);
  const messages = useSelector((state) => state?.messages);
  const [realMessage, setRealMessage] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    // Enable Pusher logging for debugging (optional, remove in production)
    Pusher.logToConsole = true;

    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the desired channel
    const channel = pusher.subscribe("my-channel");

    // Bind to an event and handle incoming data
    channel.bind("my-event", function (data) {
      // Handle the received data
      // console.log(data, "Received message from Pusher");
      setRealMessage(data);
      if (data.receiver_id === userInfo.data.id && userInfo?.data?.id !== data.sender_id) {
        // Play notification sound
        dispatch(addMessage(data));

        const audio = new Audio(notificationSound);
        audio.play();
        // Store data in localStorage
        // Overwrite the existing notifications in localStorage with the new data
        localStorage.setItem("notifications", JSON.stringify(data));
      }
      // Dispatch action to update Redux state (if needed)
      // dispatch(addMessage(data));
    });

    // Cleanup to avoid multiple subscriptions
    return () => {
      channel.unbind("my-event");
      pusher.unsubscribe("my-channel");
    };
  }, [realMessage, messages]);

  return (
    <>
      <DisableScrollForNumberInputs />
      <App />
      <ToastContainer />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RootComponent />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
