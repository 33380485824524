const ServiceRequiredDocuments = [
  {
    id: 1,
    name: "UK OR USA VISA",
    documents: [
      "Recent Passport",
      "PR card / work permit",
      "Job letter",
      "Name, Phone and Address to visit in UK or USA",
      "Bank Statement of last 3 months to be taken at appointment",
      "for student * transcripts /study permit",
    ],
  },
  {
    id: 2,
    name: "INDIA VISA",
    documents: [
      "Canadian passport",
      "Cancelled Indian passport (if available)",
      "Surrender certificate (if available)",
      "Landed paper (if available)",
      "Recent address letter or utility bill & ids",
      "Oath / citizenship certificate (if available)",
    ],
    child_documents: [
      "Canadian passport",
      "Birth certificate (long one)",
      "Letter from school / child benefit letter from government",
      "Marriage certificate",
      "All above-mentioned documents of parents",
    ],
  },
  {
    id: 3,
    name: "INDIAN PASSPORT RENEWAL",
    documents: [
      "Indian passport",
      "PR card / work permit / student permit",
      "Aadhar card (if available)",
      "Landed paper",
      "Recent address letter / utility bill or tax paper",
    ],
    child_documents: [
      "Birth certificate",
      "All above-mentioned documents of child and parents",
      "Marriage certificate of parents",
    ],
  },
  {
    id: 4,
    name: "PR CARD",
    documents: [
      "Indian Passport (old + new)",
      "PR card",
      "Landed paper",
      "Licence /photo id",
      "Social Insurance number",
      "Notice of assessment (last 1-2 years)",
    ],
  },
  {
    id: 5,
    name: "SURRENDER OF INDIAN PASSPORT",
    documents: [
      "Indian passport",
      "Oath certificate",
      "Canadian passport",
      "Landed paper",
      "Recent address letter or utility bill",
    ],
  },
  {
    id: 6,
    name: "CITIZENSHIP",
    documents: [
      "Landed paper",
      "PR card",
      "Passport (if any old too)",
      "ID cards (licence/sin/health card)",
      "Last Six-year notice of assessment",
      "12th grade certificate",
    ],
    child_documents: [
      "Passport (old, new)",
      "PR card",
      "Landed paper",
      "Birth certificate",
      "Letter from School",
      "Marriage certificate and above-mentioned all documents of parents",
    ],
  },
  {
    id: 7,
    name: "LOST LANDED PAPER",
    documents: [
      "Indian Passport",
      "PR Card / Canadian passport",
      "Recent Address letter",
    ],
  },
  {
    id: 8,
    name: "OCI",
    documents: [
      "Canadian passport",
      "Cancelled Indian passport (if available)",
      "Surrender certificate (if available)",
      "Landed paper (if available)",
      "Recent address letter or utility bill & photo ID",
      "Oath/citizenship certificate (if available)",
      "Marriage certificate or death certificate (in case of deceased spouse)",
      "All above documents of spouse",
    ],
    child_documents: [
      "Canadian Passport",
      "Birth certificate (long one)",
      "Letter from school / child benefit letter from government",
      "Marriage certificate & all above-mentioned documents of parents",
    ],
  },
  {
    id: 9,
    name: "CITIZENSHIP/OATH CERTIFICATE",
    documents: [
      "Canadian Passport",
      "Landed paper (if available)",
      "Old Indian passport (if available)",
      "Recent address letter (any)",
    ],
  },
];

export default ServiceRequiredDocuments;
