// store/store.js
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userSlice";
import messageSlice from "./messageSlice";
import VisaReducer from './VisaSlice';
const rootReducer = combineReducers({
  user: userReducer,
  messages: messageSlice,
  visa: VisaReducer, 
  // Add other reducers as needed
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Ignore specific non-serializable fields (optional)
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

const persistor = persistStore(store);
export default store;
export { store, persistor };
