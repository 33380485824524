import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  visaData: null,  // This will hold the visa data
};

// Create slice
const VisaSlice = createSlice({
  name: 'visa',
  initialState,
  reducers: {
    setVisaData: (state, action) => {
      state.visaData = action.payload; // Replace the old data with new data
    },
  },
});

// Export the action to be dispatched
export const { setVisaData } = VisaSlice.actions;

// Export the reducer to be included in the store
export default VisaSlice.reducer;
