import userResources from "api/userResources";
import Crossicon from "components/CrossIcon/Crossicon";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function Banner() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [newNumbers, setNewNumbers] = useState("");
  const [error, setError] = useState("");

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [text, setText] = useState("");
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  console.log("newNumbers: ", selectedNumbers);

  const handleCheckboxChange = (number) => {
    setSelectedNumbers((prev) =>
      prev.includes(number)
        ? prev.filter((n) => n !== number)
        : [...prev, number]
    );
  };

  const handleSelectAll = () => {
    if (selectedNumbers.length === phoneNumbers.length) {
      setSelectedNumbers([]);
    } else {
      setSelectedNumbers(phoneNumbers);
    }
  };

  const handleAddNumbers = async () => {
    const data = {
      phone_number: newNumbers,
    };

    // Show loading toast
    const toastId = toast.loading("Adding numbers...");

    try {
      // Call the API to add phone numbers
      const response = await userResources.AddphoneNumbers(data);

      if (response.status) {
        console.log("Numbers added successfully:", response);
        toast.update(toastId, {
          render: "Numbers added successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000, // Toast will auto close after 3 seconds
        });
        getNumberData();
        setNewNumbers("");
      }
    } catch (error) {
      console.log("Error adding numbers:", error);
      toast.update(toastId, {
        render: "Error adding numbers!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const Submit = async (e) => {
    e.preventDefault();

    if (!selectedImage && !text) {
      toast.error("Please provide either an image or text.");
      return;
    }

    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("message", text);

    // Add selected numbers
    selectedNumbers.forEach((number, index) => {
      formData.append(`phone_numbers[${index}]`, number);
    });

    // Show loading toast
    const loadingToastId = toast.loading("Uploading data...");

    try {
      const response = await userResources.sendWhatsappMsg(formData);

      if (response.status) {
        toast.update(loadingToastId, {
          render: "Upload successful!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        // Reset form and states
        setSelectedImage(null);
        setImagePreview(null);
        setText("");
        setSelectedNumbers([]);
      }
    } catch (error) {
      console.error("Error uploading data:", error);

      toast.update(loadingToastId, {
        render: "Error uploading data.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const filteredNumbers = phoneNumbers.filter((number) =>
    number.includes(searchTerm)
  );

  const getNumberData = async () => {
    try {
      const response = await userResources.phoneNumbersList();
      const sepreateNumbers = response.data.data.map(
        (number) => number.phone_number
      );
      setPhoneNumbers(sepreateNumbers);
      console.log(response, "number");
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNumberData();
  }, []);

  return (
    <section>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
        <div className="rounded-md border-2 p-3  sm:col-span-2">
          <div className="">
            <input
              type="number"
              placeholder="Search numbers..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-2/3 rounded border border-gray-300 bg-white p-2"
            />
            <div>
              <input
                type="checkbox"
                checked={
                  selectedNumbers.length === phoneNumbers.length ? true : false
                }
                className="ml-4 mr-2"
                onClick={handleSelectAll}
              />
              <h2
                onClick={handleSelectAll}
                className="mt-4 inline-block cursor-pointer text-primary"
              >
                {selectedNumbers.length === phoneNumbers.length
                  ? "Unselect All"
                  : "Select All"}
              </h2>
            </div>
          </div>
          <div className="mb-4 h-96 overflow-scroll rounded border p-4">
            {filteredNumbers.map((number) => (
              <div key={number} className="mb-2 flex items-center">
                <input
                  type="checkbox"
                  checked={selectedNumbers.includes(number)}
                  onChange={() => {
                    handleCheckboxChange(number);
                  }}
                  className="mr-2 "
                />
                <p className="dark:text-white">{number}</p>
              </div>
            ))}
          </div>
          <div className="mb-4 grid grid-cols-5 items-start">
            <div className="col-span-4">
              <input
                type="text"
                placeholder="Add numbers separated by commas..."
                value={newNumbers}
                onChange={(e) => {
                  setNewNumbers(e.target.value);

                  setError("");
                }}
                className={`w-full rounded border bg-white p-2 outline-none ${
                  error ? "border-red-500" : "border-gray-300"
                }`}
              />

              {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            </div>
            <button
              onClick={handleAddNumbers}
              className="col-span-1 ml-2 rounded bg-green-500 px-4 py-2 text-white"
            >
              Add
            </button>
          </div>
        </div>
        <div className="sm:col-span-4">
          <form onSubmit={Submit} className="p-4">
            <div className="mb-4 flex">
              <div
                className={`relative flex h-64 w-1/2 cursor-pointer items-center justify-center ${
                  imagePreview ? "" : "border-2"
                } border-dashed border-gray-300`}
                onClick={() => document.getElementById("imageInput").click()}
              >
                {imagePreview ? (
                  <>
                    <img src={imagePreview} alt="Selected" className="h-full" />
                    <div className="absolute -right-2 -top-2">
                      <Crossicon
                        onClose={() => {
                          setSelectedImage(null);
                          setImagePreview(null);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <span className="text-4xl text-gray-400">+</span>
                )}
              </div>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden"
              />
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Enter your text here..."
                className="ml-4 w-1/2 rounded border border-gray-300 bg-white p-2"
              />
            </div>
            <button
              type="submit"
              className="w-full rounded bg-blue-500 px-4 py-2 text-white"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
