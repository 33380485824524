import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function RowRoutesIcon({ routes, extraClass }) {
  const location = useLocation();

  return (
    <div className={`relative ${extraClass} flex flex-wrap gap-4`}>
      {routes?.map(({ label, url, icon, bg }) => {
        const isActive = bg.includes(location.pathname); // Check if currentRoute is included in the bg array

        return (
          <Link key={url} to={url}>
            <div
              className={`flex items-center justify-center gap-2 rounded-sm border bg-white px-4 py-1 shadow-md transition-all duration-300 ${
                isActive ? "bg-yellow-500 text-white" : "border-gray-300"
              } hover:border-yellow-500 hover:shadow-lg`}
            >
              <div
                className={`mb-1 text-2xl ${
                  isActive ? "text-white" : "text-gray-500"
                }`}
              >
                {icon}
              </div>
              <span className="cursor-pointer whitespace-nowrap text-sm">
                {label}
              </span>
            </div>
          </Link>
        );
      })}
    </div>
  );
}
