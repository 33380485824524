import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt, FaMapMarkerAlt, FaPhone } from "react-icons/fa";
import InputField from "components/fields/InputField";
import FormDropdown from "components/FormDropdown/FormDropdown";
import userResources from "api/userResources";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { selectUserInfo } from "../../../../redux/userSlice";
import { useSelector } from "react-redux";
import paymentStatusDummyData from "components/PaymentStatusData/PaymentStatusData";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
        label: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bangalore)",
        label: "Kempegowda International Airport (Bangalore)",
      },
      {
        value: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
        label: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
      },
    ],
  },
  {
    label: "Canada",
    options: [
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
      {
        value: "Vancouver International Airport",
        label: "Vancouver International Airport",
      },
      {
        value: "Montreal-Pierre Elliott Trudeau International Airport",
        label: "Montreal-Pierre Elliott Trudeau International Airport",
      },
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
    ],
  },
  {
    label: "United States",
    options: [
      {
        value: "Los Angeles International Airport (LAX)",
        label: "Los Angeles International Airport (LAX)",
      },
      {
        value: "John F. Kennedy International Airport (New York)",
        label: "John F. Kennedy International Airport (New York)",
      },
      {
        value: "O'Hare International Airport (Chicago)",
        label: "O'Hare International Airport (Chicago)",
      },
      {
        value: "Dallas/Fort Worth International Airport (Dallas)",
        label: "Dallas/Fort Worth International Airport (Dallas)",
      },
    ],
  },
  {
    label: "United Kingdom",
    options: [
      {
        value: "Heathrow Airport (London)",
        label: "Heathrow Airport (London)",
      },
      { value: "Gatwick Airport (London)", label: "Gatwick Airport (London)" },
      { value: "Manchester Airport", label: "Manchester Airport" },
      { value: "Edinburgh Airport", label: "Edinburgh Airport" },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        value: "Sydney Kingsford Smith Airport",
        label: "Sydney Kingsford Smith Airport",
      },
      { value: "Melbourne Airport", label: "Melbourne Airport" },
      { value: "Brisbane Airport", label: "Brisbane Airport" },
      { value: "Perth Airport", label: "Perth Airport" },
    ],
  },
];

// Array for Status options

export default function CreateEnquiryForm() {
  const Navigate = useNavigate();
  const location = useLocation();
  const updateData = location?.state;
  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo, "useri");
  console.log(updateData, "updateData");

  const [editing, setEditing] = useState(updateData ? true : false);
  const [disabled, setDisabled] = useState(false);
  const [destination, setDestination] = useState(
    updateData ? updateData.destination : ""
  );
  const [room, setRoom] = useState(updateData ? updateData.room : 1);
  const [guest, setGuest] = useState(updateData ? updateData.guest : 2);
  const [checkInDate, setCheckInDate] = useState(
    updateData
      ? updateData.check_in_date
      : new Date().toISOString().split("T")[0]
  );

  // Initialize check-out date as the day after check-in
  const [checkOutDate, setCheckOutDate] = useState(
    updateData
      ? updateData.check_out_date
      : new Date(new Date().setDate(new Date().getDate() + 1))
          .toISOString()
          .split("T")[0]
  );
  const [phoneNumber, setPhoneNumber] = useState(
    updateData?.phone_number || ""
  ); // State for phone number
  const [employeeData, setEmployeeData] = useState([]);
  const [enquirySourceData, setEnquirySourceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  // const [paymentStatusData, setPaymentStatusData] = useState([]);
  const [status, setStatus] = useState("");

  const [formData, setFormData] = useState({
    title: updateData ? updateData.title : "",
    full_name: updateData ? updateData.full_name : "",
    email: updateData ? updateData.email : "",
    phone_number: updateData ? updateData.phone_number : phoneNumber,
    enquiry_source_id: updateData ? updateData?.enquiry_source_id : "",
    booking_reference: updateData ? updateData.booking_reference : "",
    budget: updateData ? updateData.budget : "",
    invoice_number: updateData ? updateData.invoice_number : "",
    enquiry_status_id: updateData ? updateData?.enquiry_status_id : "",
    // enquiry_payment_status_id: updateData
    //   ? updateData?.enquiry_payment_status_id
    //   : "",
    assigned_to_user_id: updateData
      ? updateData?.assigned_to_user_id
      : userInfo?.data?.id,
    special_requests: updateData ? updateData.special_requests : "",
    paid_amount: updateData ? updateData.paid_amount : "",
    destination: updateData ? updateData.destination : destination?.value,
    check_in_date: checkInDate,
    check_out_date: checkOutDate,
    room: parseInt(room),
    guest: parseInt(guest),
    status: "pending",
  });
  console.log(checkInDate, "checkInDate");

  const [errors, setErrors] = useState({
    title: "",
    full_name: "",
    email: "",
    phone_number: "",
    enquiry_source_id: "",
    booking_reference: "",
    budget: "",
    invoice_number: "",
    enquiry_status_id: "",
    // enquiry_payment_status_id: "",
    assigned_to_user_id: "",
    special_requests: "",
    paid_amount: "",
    destination: "",
    checkInDate: "",
    checkOutDate: "",
    room: "",
    guest: "",
  });

  const validateForm = () => {
    const newErrors = {};

    // Required field validations
    if (!formData.title?.trim()) {
      newErrors.title = "Title is required";
    }
    if (!formData.full_name?.trim()) {
      newErrors.full_name = "Customer name is required";
    }
    if (!formData.email?.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is not valid";
    }
    if (!formData.phone_number?.trim()) {
      newErrors.phone_number = "Phone number is required";
    }
    // Add validations for other fields
    if (!destination) newErrors.destination = "Destination is required.";
    if (!checkInDate) newErrors.checkInDate = "Check In Date is required.";
    if (!checkOutDate) newErrors.checkOutDate = "Check Out Date is required.";

    if (!formData.enquiry_source_id) {
      newErrors.enquiry_source_id = "Enquiry source is required";
    }
    // if (!formData.booking_reference?.trim()) {
    //   newErrors.booking_reference = "Booking reference is required";
    // }
    if (!formData.budget) {
      newErrors.budget = "Budget is required";
    }
    if (!formData.invoice_number?.trim()) {
      newErrors.invoice_number = "Invoice number is required";
    }
    if (!formData.enquiry_status_id) {
      newErrors.enquiry_status_id = "Enquiry status is required";
    }
    // if (!formData.enquiry_payment_status_id) {
    //   newErrors.enquiry_payment_status_id = "Payment status is required";
    // }
    if (!formData.assigned_to_user_id) {
      newErrors.assigned_to_user_id = "Assigned user ID is required";
    }
    if (!formData.paid_amount) {
      newErrors.paid_amount = "Paid amount is required";
    }

    // Set the errors state
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submissionData = {
      ...formData,
      destination: destination.value,
      check_in_date: checkInDate,
      check_out_date: checkOutDate,
      room: parseInt(room),
      guest: parseInt(guest),
      status: updateData ? status : "pending",
    };

    if (updateData) {
      submissionData.id = updateData.id;
    }
    console.log("Form Data Submitted:", submissionData);

    if (validateForm()) {
      setDisabled(true); // Disable the button during submission
      toast.loading("Submitting...", { toastId: "loadingToast" }); // Show loading toast

      try {
        const response = await submitEnquiry(submissionData);

        if (response.status) {
          setFormData({
            title: "",
            full_name: "",
            email: "",
            phone_number: "",
            enquiry_source_id: "",
            booking_reference: "",
            budget: "",
            invoice_number: "",
            enquiry_status_id: "",
            // enquiry_payment_status_id: "",
            assigned_to_user_id: "",
            special_requests: "",
            paid_amount: "",
          });
          // Reset additional states
          setPhoneNumber(null);
          setDestination("");
          setCheckInDate(checkOutDate);
          setCheckOutDate(new Date().toISOString().split("T")[0]);
          setGuest(2);
          setRoom(1);
          setErrors({});

          toast.update("loadingToast", {
            render: updateData
              ? "Record updated successfully!"
              : "Submission completed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        }
        setTimeout(() => {
          Navigate(
            updateData?.NavigateTo === "hotelassignenquirylist"
              ? "/admin/hotel-assigned-enquirys"
              : updateData?.NavigateTo === "hotelcreatedenquiryList"
              ? "/admin/hotel-created-enquirys"
              : updateData?.navigateToAllList === true
              ? "/admin/hotel-enquirys"
              : "/admin/hotel-enquirys"
          );
        }, 3000);
        console.log(response);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";

        toast.update("loadingToast", {
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } finally {
        setDisabled(false); // Re-enable the button after submission
      }
    }
  };
  // Helper function for API call logic
  const submitEnquiry = async (submissionData) => {
    if (updateData?.NavigateTo === "hotelassignenquirylist") {
      return userResources.createAssignedEnquirys(submissionData);
    } else {
      return userResources.addHotel(submissionData);
    }
  };

  const fetchData = async () => {
    try {
      // Define an array of API calls
      const apiCalls = [
        {
          method: userResources.adminemployeeslist,
          // params: {},
          callback: setEmployeeData,
          errorMsg: "User API call failed:",
        },
        {
          method: userResources.getSources,
          // params: {},
          callback: setEnquirySourceData,
          errorMsg: "Error fetching Enquiry Source data:",
        },
        {
          method: userResources.enquityStatus,
          // params: {},
          callback: setStatusData,
          errorMsg: "Error fetching Enquiry Status data:",
        },
      ];

      // Call all APIs simultaneously using Promise.allSettled
      const results = await Promise.allSettled(
        apiCalls.map((api) => api.method(api.params))
      );

      // Process each result
      results.forEach((result, index) => {
        if (result.status === "fulfilled" && result.value?.status) {
          const data = result.value.data.data.map((item) => ({
            value: item.id,
            label: item.name || item.airline_name, // Use item.airline_name if it exists
          }));
          apiCalls[index].callback(data);
        } else {
          console.log(apiCalls[index].errorMsg, result.reason);
        }
      });

      // Set dummy payment status data
      // setPaymentStatusData(paymentStatusDummyData);
    } catch (error) {
      console.log("An unexpected error occurred:", error);
    }
  };

  // Call fetchData inside useEffect
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (updateData) {
      const nextDay = new Date(checkInDate);
      nextDay.setDate(nextDay.getDate() + 1);
      setCheckOutDate(nextDay.toISOString().split("T")[0]);
    }
  }, [checkInDate]);

  return (
    <section className="max-w-full py-2">
      <form onSubmit={handleSubmit} className="">
        <div className="grid grid-cols-1 gap-1">
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Enter Title"
              name="title"
              value={formData.title}
              onChange={(e) => handleChange("title", e.target.value)}
              state={errors.title ? "error" : ""}
              errorMessage={errors.title} // Pass the error message here
              placeholder="Enter Title"
            />
            <InputField
              label="Customer Name" // Ensure to keep the label
              name="full_name" // Use name to match formData structure
              value={formData.full_name} // Controlled input for customer name
              onChange={(e) => handleChange("full_name", e.target.value)} // Handle changes
              state={errors.full_name ? "error" : ""} // Set state based on errors
              errorMessage={errors.full_name} // Pass the error message here
              placeholder="Customer Name" // Placeholder for the input field
            />
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Email Address"
              name="email" // Use name for form data consistency
              type="email" // Set type to "email" for validation
              placeholder="Customer Email"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes
              value={formData.email} // Controlled input
              onChange={(e) => handleChange("email", e.target.value)} // Handle changes
              state={errors.email ? "error" : ""} // Set state based on errors
              errorMessage={errors.email} // Pass the error message here
            />

            <div>
              <label
                htmlFor="phone"
                className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]"
              >
                Phone Number
              </label>
              <div className="relative">
                <div className="flex">
                  <PhoneInputWithCountrySelect
                    defaultCountry="CA"
                    value={phoneNumber}
                    onChange={(value) => {
                      setPhoneNumber(value);
                      setFormData((prevFormData) => ({
                        ...prevFormData,
                        phone_number: value,
                      }));
                      if (value) {
                        setErrors((prevErrors) => ({
                          ...prevErrors,
                          phone_number: "",
                        })); // Clear phone_no error if value is provided
                      }
                    }}
                    placeholder="Enter Phone Number"
                    // required
                    className="w-full rounded-md border p-2 text-lg focus:outline-none focus:ring-2 dark:!border-white/10"
                  />
                </div>
                <div className="absolute right-4 top-4">
                  <FaPhone />
                </div>
              </div>
              {errors.phone_number && (
                <span className="text-sm text-red-600">
                  {errors.phone_number}
                </span>
              )}
            </div>
          </div>

          <div className="mt-2 grid grid-cols-1 gap-5 rounded-md border text-white bg-gray-900 py-5 sm:grid-cols-2">
            {/* Destination Field */}
            <div className=" border-r-0 px-3 sm:border-r">
              <div className="flex items-center gap-2">
                <FaMapMarkerAlt />
                <label className="text-md  text-yellow-500">
                  ENTER YOUR DESTINATION OR PROPERTY
                </label>
              </div>
              <Select
                value={destination}
                onChange={(value) => {
                  setDestination(value);
                  setErrors((prev) => ({ ...prev, destination: "" })); // Clear error message
                }}
                options={options}
                isSearchable={true}
                placeholder="Enter destination"
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex w-full cursor-pointer mt-2 relative text-gray-900 border-0 text-lg overflow-hidden  transition-all outline-none rounded-sm duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white  hover:border-gray-400 cursor-pointer"
                    }`,
                  menu: "z-10 w-full bg-white absolute shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-900",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-gray-900`
                        : `text-gray-900 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                }}
              />
              {errors.destination && (
                <p className="text-red-500">{errors.destination}</p>
              )}
            </div>

            {/* Check-in Date Field */}
            <div className="px-3 sm:px-0 sm:pr-3">
              <div className="flex items-center gap-2">
                <FaCalendarAlt />
                <label className="text-md text-yellow-500">CHECK-IN DATE</label>
              </div>
              <input
                type="date"
                value={checkInDate}
                onChange={(e) => {
                  setCheckInDate(e.target.value);
                  setErrors((prev) => ({ ...prev, checkInDate: "" })); // Clear error message
                  if (e.target.value > checkOutDate) {
                    setCheckOutDate(e.target.value); // Reset drop date to pickup date if it's earlier
                  }
                }}
                className="mt-2 w-full rounded-sm bg-white p-2 text-gray-900"
                min={new Date().toISOString().split("T")[0]} // Disable past dates
              />
              {errors.checkInDate && (
                <p className="text-red-500">{errors.checkInDate}</p>
              )}
            </div>

            {/* Check-out Date Field */}
            <div className="border-r-0 px-3 sm:border-r">
              <div className="flex items-center gap-2">
                <FaCalendarAlt />
                <label className="text-md text-yellow-500">
                  CHECK-OUT DATE
                </label>
              </div>
              <input
                type="date"
                value={checkOutDate}
                onChange={(e) => {
                  setCheckOutDate(e.target.value);
                  setErrors((prev) => ({ ...prev, checkOutDate: "" })); // Clear error message
                }}
                className="mt-2 w-full rounded-sm bg-white p-2 text-gray-900"
                min={checkInDate || new Date().toISOString().split("T")[0]} // Disable dates before Check-in Date
              />
              {errors.checkOutDate && (
                <p className="text-red-500">{errors.checkOutDate}</p>
              )}
            </div>

            {/* Rooms & Guests Field */}
            <div className="px-3 sm:px-0">
              <div className="flex items-center gap-2">
                <FaCalendarAlt />
                <label className="text-md text-yellow-500">
                  ROOMS & GUESTS
                </label>
              </div>
              <div className="dropdown w-full">
                <div tabIndex={0} role="button" className="cursor-pointer">
                  <h3 className="text-lg text-white">
                    {room} Room{room > 1 ? "s" : ""}, {guest} Guest
                    {guest > 1 ? "s" : ""}
                  </h3>
                </div>
                <div
                  tabIndex={0}
                  className="dropdown-content z-[1] mt-2 w-52 bg-white p-2 text-gray-900 shadow"
                >
                  <div className="flex items-center justify-between p-2 hover:bg-gray-100">
                    <h4>Rooms</h4>
                    <div className="flex items-center border">
                      <div
                        className="p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (room > 1) setRoom(room - 1);
                        }}
                      >
                        -
                      </div>
                      <div className="px-3">{room}</div>
                      <div
                        className="p-1 px-3 hover:bg-green-500 hover:text-white"
                        onClick={() => setRoom(room + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-2 hover:bg-gray-100">
                    <h4>Guests</h4>
                    <div className="flex items-center border">
                      <div
                        className="p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (guest > 1) setGuest(guest - 1);
                        }}
                      >
                        -
                      </div>
                      <div className="px-3">{guest}</div>
                      <div
                        className="p-1 px-3 hover:bg-green-500 hover:text-white"
                        onClick={() => setGuest(guest + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Form Fields */}
          </div>

          <div className="grid grid-cols-1 items-center gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Enquiry Source
              </label>
              <Select
                value={enquirySourceData.find(
                  (source) => source.value === formData.enquiry_source_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  handleChange(
                    "enquiry_source_id",
                    selectedOption?.value || ""
                  ); // Update handler
                }}
                options={enquirySourceData} // Format options
                isSearchable={true} // Enable search functionality
                placeholder="Select..."
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.enquiry_source_id,
                  errorsBorder: errors.enquiry_source_id ? true : false,
                })} // Pass parameters here}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.enquiry_source_id && (
                <span className="text-xs text-red-500">
                  {errors.enquiry_source_id}
                </span>
              )}
            </div>
            {/* Assigned To */}
            <div>
              <label className="my-2 block text-sm  font-medium text-gray-900 dark:text-[#bb8e25]">
                Assigned To
              </label>
              <Select
                value={employeeData.find(
                  (employee) => employee.value === formData.assigned_to_user_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  setStatus("pending");
                  handleChange(
                    "assigned_to_user_id",
                    selectedOption?.value || ""
                  );
                }} // Handle change
                options={employeeData}
                isDisabled={updateData?.status === "accept" ? true : false}
                isSearchable={true}
                placeholder="Select..."
                classNames={getSelectClassNames({
                  isDisabled: updateData?.status === "accept" ? true : false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.assigned_to_user_id,
                  errorsBorder: errors.assigned_to_user_id ? true : false,
                })} // Pass parameters here}}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.assigned_to_user_id && (
                <span className="text-xs text-red-500">
                  {errors.assigned_to_user_id}
                </span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Price Quote"
              name="budget" // Use name for form data consistency
              type="number" // Keep type as "text" or adjust to "number" if preferred
              placeholder="Price Quote"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.budget} // Controlled input
              onChange={(e) => handleChange("budget", e.target.value)} // Handle changes
              state={errors.budget ? "error" : ""} // Set state based on errors
              errorMessage={errors.budget} // Pass the error message here
            />

            <InputField
              label="Paid Amount"
              id="paid_amount" // Add an id for accessibility
              name="paid_amount" // Ensure name matches form data structure
              type="number" // Keep as "text" or change to "number" if appropriate
              placeholder="Paid Amount"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.paid_amount} // Controlled input
              onChange={(e) => handleChange("paid_amount", e.target.value)} // Handle changes
              state={errors.paid_amount ? "error" : ""} // Set state based on errors
              errorMessage={errors.paid_amount} // Pass the error message here
            />
          </div>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            {/* Payment Status */}
            {/* <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Payment Status
              </label>
              <Select
                value={paymentStatusData.find(
                  (status) =>
                    status.value === formData.enquiry_payment_status_id
                )} // Controlled value
                onChange={(selectedOption) => {
                  handleChange(
                    "enquiry_payment_status_id",
                    selectedOption?.value || ""
                  ); // Update handler
                }}
                options={paymentStatusData} // Format options
                isSearchable={true} // Enable search functionality
                placeholder="Select Payment Status"
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.enquiry_payment_status_id,
                  errorsBorder: errors.enquiry_payment_status_id ? true : false,
                })} // Pass parameters here}}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.enquiry_payment_status_id && (
                <span className="text-xs text-red-500">
                  {errors.enquiry_payment_status_id}
                </span>
              )}
            </div> */}
            {/* Status */}
            <div>
              <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
                Status
              </label>
              <Select
                value={statusData.find(
                  (status) => status.value === formData.enquiry_status_id
                )}
                onChange={(selectedOption) => {
                  handleChange(
                    "enquiry_status_id",
                    selectedOption?.value || ""
                  );
                }}
                options={statusData}
                isSearchable={true}
                placeholder="Select Status"
                classNames={getSelectClassNames({
                  isDisabled: false,
                  selectProps: formData,
                  isSelected: false,
                  value: formData.enquiry_status_id,
                  errorsBorder: errors.enquiry_status_id ? true : false,
                })} // Pass parameters here
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? "bg-gray-200 text-gray-900"
                        : "bg-blue-800 text-gray-200"
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.enquiry_status_id && (
                <span className="text-xs text-red-500">
                  {errors.enquiry_status_id}
                </span>
              )}
            </div>
            <InputField
              label="Invoice Number"
              name="invoice_number" // Use name for form data consistency
              type="text" // Keep type as "text" or adjust to "number" if preferred
              placeholder="Invoice Number"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.invoice_number} // Controlled input
              onChange={(e) => handleChange("invoice_number", e.target.value)} // Handle changes
              state={errors.invoice_number ? "error" : ""} // Set state based on errors
              errorMessage={errors.invoice_number} // Pass the error message here
            />
          </div>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Booking Reference No."
              name="booking_reference" // Use name for form data consistency
              type="number" // Keep type as "text" for general text input
              placeholder="Booking Reference No."
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.booking_reference} // Controlled input
              onChange={(e) =>
                handleChange("booking_reference", e.target.value)
              } // Handle changes
              state={errors.booking_reference ? "error" : ""} // Set state based on errors
              errorMessage={errors.booking_reference} // Pass the error message here
            />
            <InputField
              label="Special Requests"
              id="special_requests" // Add an id for accessibility
              name="special_requests" // Ensure name matches form data structure
              type="text" // Keep type as "text"; this is optional for textareas
              placeholder="Special Requests"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
              value={formData.special_requests} // Controlled input; corrected to use 'remarks'
              onChange={(e) => handleChange("special_requests", e.target.value)} // Handle changes
              state={errors.special_requests ? "error" : ""} // Set state based on errors
              errorMessage={errors.special_requests} // Pass the error message here
              isTextArea={true} // Indicate that this is a textarea
            />
          </div>
          <div className="grid grid-cols-1 sm:gap-4">
            <div className="flex w-full justify-center">
              <SubmitButton
                editing={editing}
                disabled={disabled}
                add="Submit"
                edit="Update"
                extraClass="btn mt-4 w-full border-0"
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
}
