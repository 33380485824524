import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const DateFilter = ({
  selectedDate,
  setSelectedDate,
  column,
  minDate,
  maxDate,
}) => {
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    column?.setFilterValue(newDate); // Apply column filter directly
  };

  const clearDate = () => {
    setSelectedDate("");
    column?.setFilterValue(null); // Clear column filter
  };

  return (
    <div className="flex w-full items-center gap-2">
      <input
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        min={minDate} // Dynamically set minimum date
        max={maxDate} // Dynamically set maximum date
        className="w-full border-0 border-b-2 bg-transparent pb-2 outline-none hover:border-gray-800"
      />
      {selectedDate && (
        <button
          onClick={clearDate}
          className="p-1 text-gray-500 hover:text-black"
        >
          <AiOutlineClose size={16} />
        </button>
      )}
    </div>
  );
};

export default DateFilter;
