import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaPlane } from "react-icons/fa"; // Importing the plane icon for departments
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { selectUserInfo } from "../../../../redux/userSlice";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export default function InvoiceList() {
  const userInfo = useSelector(selectUserInfo);
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState([]);
  console.log(invoiceData, "invoicedata");

  useEffect(() => {
    setInvoiceData(location.state);
  }, []);

  return <section className="mt-6">InvoiceList</section>;
}
