import Widget from "components/widget/Widget";
import { selectUserInfo } from "../../../redux/userSlice";
import PieChartCard from "../default/components/PieChartCard";
import Banner from "./components/Banner";
import Project from "./components/Project";
import { useSelector } from "react-redux";
import { FaHotel, FaMapMarkedAlt, FaUserTie } from "react-icons/fa";
import { RiMessage2Line } from "react-icons/ri";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import userResources from "api/userResources";
import { IoMdAirplane } from "react-icons/io";
import Dashboard from "../default";

const ProfileOverview = () => {
  const userInfo = useSelector(selectUserInfo);

  return (
    <div className="flex w-full flex-col gap-5">
      <div className="w-ful mt-3 flex h-fit flex-col gap-5">
        <div className="col-span-4 lg:!mb-0">
          <Banner data={userInfo?.data} />
        </div>
      </div>

      <Dashboard
        ProfileData={{ profileData: true }}
        assignListNavigate={"/admin/assigned-enquiry-list"}
        createdListNavigate={"/admin/created-enquiry-list"}
        hotelprofileData={{ hotelprofileData: true }}
        hotelassignListNavigate={"/admin/hotel-assigned-enquirys"}
        hotelcreatedListNavigate={"/admin/hotel-created-enquirys"}
      />

    </div>
  );
};

export default ProfileOverview;
